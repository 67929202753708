.send-in-chat-body{
    max-width:640px;
}

.send-in-chat-header,.send-in-chat-recent{
    padding:0 26px 21px 26px;
}

.send-in-chat-title{
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
}

.send-in-chat-body .post-information{
    background-color:#f9f8fd;
    border-radius:8px;
}

.send-in-chat-post-box{
    display:flex;
    align-items:center;
    margin-top:20px;
}

.send-in-chat-post-box img{
    width:42px;
    height:42px;
}

.send-in-chat-post-box input{
    padding:10px 16px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
    opacity: 0.5;
    border:none;
    outline:none;
    width:100%;
}

.send-in-chat-divider{
    height: 1px;
    width:100%;
    background-color: rgba(63, 82, 109, 0.28);
}

.search-bar{
    border-radius: 22px;
    border-width: 1px;
    border-style: solid;
    padding-left:20px;
    padding-right:8px;
    display:flex;
    align-items:center;
    /*margin:19px 26px 0 26px;*/
    margin-top:19px;
    height: 44px;
}

.search-input{
    border:none;
    outline:none;
    height:100%;
    width:100%;
    padding-top:12px;
    padding-bottom:12px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color:#3f526d;
    opacity:0.4;
}

.search-button .search-icon{
    font-size: 27px;
}

.recent-title{
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
}

.recent-activity-item{
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin-bottom:18px;
}

.recent-information{
    display:flex;
    align-items:center;
}

.recent-information h3{
    margin-left:14px;
}

.send-recent-button{
    height:38px;
    text-transform:capitalize!important;
    padding:19px 33px!important;
    box-shadow:none!important;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    opacity: 0.7;
}

.send-recent-button:hover {
    opacity: 1;
}