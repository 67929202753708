.add-file-dialog-body{
    display:flex;
    flex-direction:column;
    margin:10px 21px;
}

.add-file-dialog-body img{
    width:70px;
}

.add-file-dialog-title{
    font-size: 18px;
    font-weight: 600;
    color:#3f526d;
    margin:0;
}

.add-file-dropzone-container{
    /*padding: 25px 255px 67px 91px;*/
    border-radius: 8px;
    border: dotted 0.5px rgba(37, 56, 115, 0.5);
    background-color: #f9f8fd;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    padding:25px 92px 20px 93px;
    margin-top:20px;
    margin-bottom:40px;
}
/*git commit -m "[finishes #798344] Add AnswerDialog | ShareToFeed | SendToChat dialogs, Badges tab data, discussion forum tab data design, some improvements on components, Add functionality to add comments"*/
.add-file-dropzone-container p{
    opacity: 0.5;
    font-size: 16px;
    color: #253873;
    margin-top:25px;
}

.add-file-dialog-body button{
    text-transform:none!important;
    line-height:normal!important;
}

.add-file-button{
    font-size: 16px!important;
    font-weight: 600!important;
    padding:13px 39px!important;
    border-radius:8px!important;
}

.add-file-upload-buttons{
    display:flex;
    gap:10px;
    margin-bottom:22px;
}

.upload-file-button, .cancel-file-button{
    font-size: 18px!important;
    font-weight: 600!important;
    padding:15px 48px!important;
    border-radius:8px!important;
}

.cancel-file-button{
    color:#3f526d!important;
}

.file-list{
    margin-bottom:40px;
}

.file-list-item{
    display:flex;
    justify-content: space-between;
    align-items:center;
}

.file-list-item div{
    display:flex;
    align-items:center;
}

.file-list-item .file-name,
.file-list-item .file-upload-time{
    font-family: OpenSans,sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
}

.file-list-item .file-name{
    font-size: 18px;
    margin-left:20px;

}

.file-list-item .file-upload-time{
    opacity: 0.6;
    font-size: 14px;
    margin-right:18px;
}

.file-list-item .file-icon{
    width:32px;
    height:32px;
}
