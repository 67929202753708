.landing-page-main-container .tab-menu.box-view{
    display:flex;
    align-items:center;
    gap:30px;
    margin-bottom: 7px;
}

.landing-page-main-container .tab-menu .MuiButtonBase-root {
    font-weight: normal;
    padding-left: 17px;
    padding-right: 17px;
}

.landing-page-main-container .tab-menu .MuiButtonBase-root[aria-selected="true"] {
    font-weight: 600;
    /* color: inherit ; */
}

.filter-button{
    min-width:50px;
    height:50px;
    margin-bottom:24px;
    border-radius: 8px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
}

.filter-button img{
    width:24px;
    height:24px;
}

.filter-button button{
    min-width:unset;
    height:100%;
    width:100%;
    border-radius:8px;
    padding:0;
}

.filter-menu .MuiPopover-paper{
    border-radius: 8px!important;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);
    padding:16px 20px!important;
}

.filter-container{
    width:220px;
}

.filter-container .MuiMenu-list{
    padding:0;
}

.filter-menu-header{
    display:flex;
    justify-content: space-between;
    align-items:center;
    border-bottom:solid 0.5px #3f526d;
    margin-bottom:14px;
}

.filter-menu-header h3, .filter-menu-header span{
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;;
}

.filter-menu-header h3{
    font-size: 14px;
    font-weight: 600;
}

.filter-menu-header span{
    font-size:12px;
    text-decoration:underline;
    cursor:pointer;
}

.filter-select-box,
.filter-multiple-select-box{
    width:100%;
    margin-top:11px!important;
    margin-bottom:24px!important;
}

.filter-container label,
.filter-multiple-select-search{
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
}

.filter-select-box label{
    color:rgba(0, 0, 0, 0.3)!important;
}

.filter-multiple-select-box .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
    padding:2px 9px;
}

.filter-menu-buttons{
    display:flex;
    align-items:center;
    gap:10px;
}

.filter-container .filter-button{
    font-size: 14px;
    font-weight: 600!important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal!important;
    letter-spacing: normal;
    text-align: center;
    text-transform:capitalize!important;
    width:100px;
    height:36px;
    padding:9px 30px!important;
    border-radius: 6px;
}

.filter-container .filter-button:last-child{
    border-width: 0.5px;
    border-style: solid;
    color: #3f526d;
}

.select-box-chips{
    display:flex;
    gap:2px;
}

.MuiChip-root{
    border-radius: 4px!important;
    background-color: rgb(127, 108, 252,0.1)!important;
    font-size: 14px!important;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #253873!important;
    /*margin:0 5px 5px 0!important;*/
}

.filter-container .filter-select-box .MuiSelect-select{
    padding-top: 10px;
    padding-bottom: 10px;
}

.filter-select-box .MuiInputLabel-outlined{
    transform: translate(14px, 12px) scale(1)!important;
}

.filter-select-box .MuiInputLabel-outlined.MuiFormLabel-filled{
    display:none;
}

.filter-multiple-select-search .MuiInputBase-input::placeholder{
    color: #3f526d!important;
}

.back-button{
    font-family: OpenSans,sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-transform:capitalize!important;
    margin-bottom:24px!important;
    padding:14px 20px!important;
    height:50px;
    border-radius:8px!important;
    box-shadow:none!important;
    margin-right:20px!important;
}

.back-button img{
    width:18px;
    height:18px;
}

.tab-menu.answer-view{
    gap:0;
}

.tab-menu.answer-view .filter-button{
    margin-left:10px!important;
}

.student-filter-select-boxes{
    display:flex;
    align-items:center;
    gap:14px;
}

.student-filter-select-box{
    width:200px;
    position: relative;
}

.student-filter-select-box .progress-icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: wait;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.student-filter-select-box fieldset{
    border:none;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius:8px!important;
}

.student-filter-select-box .MuiSelect-select{
    border-radius:8px!important;
    padding:15px 20px;
    /*padding-top: 10px;*/
    /*padding-bottom: 10px;*/
}

.student-filter-select-boxes .MuiInputLabel-outlined{
    transform: translate(15px, 18px) scale(1)!important;
}

.student-filter-select-boxes .MuiInputLabel-outlined.MuiFormLabel-filled{
    display:none;
}

/*.filter-select-box-search{*/
/*    outline:none;*/
/*    border:none;*/
/*    padding:10px 20px;*/
/*    width:180px;*/
/*    font-size:14px;*/
/*    font-family: Open Sans,sans-serif;*/
/*    font-weight: 600;*/
/*    font-stretch: normal;*/
/*    font-style: normal;*/
/*    line-height: normal;*/
/*    letter-spacing: normal;*/
/*    color: rgba(63, 82, 109,0.5)!important;*/
/*    border-bottom:1px solid rgba(63, 82, 109,0.5)*/
/*}*/

.filter-select-box legend,.MuiAutocomplete-root label,.MuiInputLabel-outlined label{
    /*display:none!important;*/
}

.student-search-form{
    margin-bottom:20px!important;
    width:100%;
}

.student-search-input{
    border-radius:6px!important;
    /*opacity: 0.5;*/
    font-family: Open Sans,sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(63, 82, 109,0.5)!important;
}

.student-search-input input{
    padding:18px 20px!important;
}

.student-search-input img{
    width:20px;
    height:20px;
    margin-right:5px;
}

.student-list-container{
    display:flex;
    flex-wrap:nowrap
}

.student-list-container .user-list-wrapper{
    display:flex;
    flex-wrap:wrap;
    margin:-10px;
    width:calc(100% + 20px);
}

.student-list-container .user-list-item{
    flex:0 0 25%;
    max-width:25%;
    box-sizing:border-box;
    padding:10px;
}

@media(max-width:1400px){
    .student-list-container .user-list-item{
        flex:0 0 33.33%;
        max-width:33%;
    }
}

@media(max-width:960px){
    .student-list-container .user-list-item{
        flex:0 0 50%;
        max-width:50%;
    }
}

.student-list-filters-container{
    display:flex;
    align-items:center;
    justify-content: space-between;
    margin-bottom:30px;
}

.student-list-filters{
    display:flex;
    align-items:center;
}

.student-list-filters .page-title{
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
    margin-right:78px;
}

.my-profile-button{
    padding: 14px 48px!important;
    border-radius: 8px!important;
    text-transform:capitalize!important;
    font-size:16px!important;
    font-weight:600!important;
}

.widget-menu .MuiPopover-paper {
    padding:20px;
    width:220px;
    border-radius:8px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.24);
}

.widget-menu-buttons{
    display:flex;
    justify-content:space-between;
    margin-top:16px;
}

.widget-menu-form span{
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: normal;
}

.widget-menu-buttons .widget-button{
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    text-transform:capitalize!important;
    box-shadow:none;
    width:84px;
    height:30px;
    border-radius:4px;
}

.widget-menu-buttons .widget-button:last-child{
    background-color: #f6f6f6;
    color:#3f526d;
}
