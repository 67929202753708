@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap);

#login-page {
    padding-top: 25px;
}

#login-page .login-card {
    width: 600px;
    max-width: 100%;
    margin:0 auto;
    padding: 20px;
}

#login-page .login-card .field-item {
    margin-top: 20px;
}

#login-page .login-card .sign-in-button {
    margin: 0 auto;
    margin-top: 20px;
}


.profile-summary-view-wrapper.__narrow {
  text-align: center;
}

.profile-summary-view-wrapper.__narrow .profile-picture-area{
  border-radius: 50%;
  width: 62px;
  height: 62px;
  margin: 0 auto;
  margin-bottom: 10px;
  overflow: hidden;
}

.profile-summary-view-wrapper.__wide .profile-picture-area{
  border-radius: 50%;
  width: 112px;
  height: 112px;
  margin: 0 auto;
  margin-bottom: 14px;
  overflow: hidden;
}

.profile-summary-view-wrapper .profile-picture-area .image-wrapper{
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
}

.profile-summary-view-wrapper .profile-picture-area img{
  max-width: 100%;
  height: auto;
}

/* @LEFT SIDE - Titles */
.profile-summary-view-wrapper.__wide .profile-title .fullname {
  margin-bottom: 6px;
  font-size: 20px;
  font-weight: bold;
  color: #3f526d;
}
.profile-summary-view-wrapper.__narrow .profile-title .fullname {
  margin-bottom: 3px;
  font-size: 16px;
  font-weight: bold;
  color: #3f526d;
}

.profile-summary-view-wrapper .profile-title .title-line {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-summary-view-wrapper.__wide .profile-title .title-line .title{
  color: #3f526d;
  font-size: 16px;
  opacity: 0.68;
}

.profile-summary-view-wrapper.__narrow .profile-title .title-line .title{
  font-size: 16px;
  color: rgba(63, 82, 109, 0.5);
  font-weight: 600;
}

.profile-summary-view-wrapper .profile-title .verified-user-icon {
  color: #2d97ff;
  width: 12px;
  height: 12px;
  margin-left: 4px;
}


.profile-summary-view-wrapper.__wide .user-summary-count-wrapper{ margin-top: 40px; }
.profile-summary-view-wrapper.__narrow .user-summary-count-wrapper{ margin-top: 19px; }

.profile-summary-view-wrapper .user-summary-count-wrapper .summary-item {
  display: flex;
  align-items: center;
}

.profile-summary-view-wrapper.__wide .user-summary-count-wrapper .summary-item .count {
  margin-left: 21px;
  margin-right: 4px;
  font-size: 18px;
  color: #3f526d;
  font-weight: bold;
}

.profile-summary-view-wrapper.__wide .user-summary-count-wrapper .summary-item .item-name {
  font-size: 18px;
  color: rgba(63, 82, 109, 0.5);
}

.profile-summary-view-wrapper.__narrow .user-summary-count-wrapper .summary-item .count {
  margin-left: 10px;
  color: rgba(63, 82, 109, 0.5);
  font-size: 16px;
  font-weight: 600;
}

.profile-summary-view-wrapper.__wide .user-summary-count-wrapper .summary-item {
  margin-bottom: 10px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  height: 64px;
}

.profile-summary-view-wrapper.__narrow .user-summary-count-wrapper {
  display: inline-block;
  text-align: center;
}

.profile-summary-view-wrapper.__narrow .user-summary-count-wrapper .summary-item{
  box-shadow: none;
  margin: 0;
  padding: 10px;
  display: inline-block;
  float: left;
  background-color: unset;
}

.profile-summary-view-wrapper.__narrow .user-summary-count-wrapper .summary-item:nth-child(3){
  clear: left; 
}

.profile-summary-view-wrapper.__narrow .user-summary-count-wrapper .summary-item > *{
  vertical-align: middle;
}

#sidebar {
    padding: 26px 0px;
    text-align: center;
    position: relative;
    z-index: 3;
    transition: 300ms ease;
}

#sidebar.__collapsed {
    flex: 0 0 120px;
    max-width: 120px;
    width: 120px;
}

#sidebar:not(.__collapsed) {
    flex: 0 0 265px;
    max-width: 265px;
    width: 265px;
}

#sidebar .sidebar-collapser {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 115px;
    right: -15px;
    background-color: #ffbe00;
    max-width: 30px;
    min-width: unset;
    z-index: 1;
}

#sidebar .sidebar-collapser .chevron-icon {
    transition: 300ms ease transform;
    transition-delay: 300ms;
}

#sidebar:not(.__collapsed) .sidebar-collapser .chevron-icon {
    transform: rotate(180deg);
}

#sidebar .nav-menu-wrapper {
    text-align: center;
}

#sidebar .nav-menu-wrapper .nav-menu-item {
    opacity: 0.5;
    display: block;
    min-width: unset;
    text-transform: unset;
    padding-left: 45px;
    padding-right: 15px;
    width: 100%;
    margin: 0;
    min-height: 40px;
    height: 80px;
    height: calc(100vh / 11.5);
    max-height: 90px;
}

#sidebar:not(.__collapsed) .nav-menu-wrapper .nav-menu-item {
    width: 100%;
}

#sidebar .nav-menu-wrapper .nav-menu-item:hover {
    opacity: 1;
}

#sidebar .nav-menu-wrapper .nav-menu-item:first-child {
    margin-top: 40px;
}

#sidebar .nav-menu-wrapper {}

#sidebar.__collapsed .nav-menu-wrapper .nav-menu-item .button-text {
    opacity: 0;
    transform: translateX(150%);
}

#sidebar .nav-menu-wrapper .nav-menu-item .button-text {
    opacity: 1;
    transform: translateX(0%);
    transition: 300ms ease;
}

#sidebar .nav-menu-wrapper .nav-menu-item .MuiButton-label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
}

#sidebar .nav-menu-wrapper .nav-menu-item .button-text {
    margin-left: 16px;
    color: #fff;
    font-size: 16px;
    pointer-events: none;
}
.header-container {
    padding-right: 10px;
}

header#app-header {
    height: 88px;
}

header#app-header #page-header {
    height: 100%;
}

header#app-header #page-header .page-header-button-text {
    font-size: 20px;
}

header#app-header .header-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

header#app-header .action-icons {
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    flex-wrap: nowrap;
    margin-right: 64px;
    position: relative;
    height: 48px;
}

header#app-header .action-icons img {
    -o-object-fit: contain;
       object-fit: contain;
}

header#app-header .action-icons.__show .action-item-wrapper:nth-child(5) { transition-delay: 100; transform: translateX(0); }
header#app-header .action-icons.__show .action-item-wrapper:nth-child(4) { transition-delay: 200; transform: translateX(-48px); }
header#app-header .action-icons.__show .action-item-wrapper:nth-child(3) { transition-delay: 300; transform: translateX(-96px); }
header#app-header .action-icons.__show .action-item-wrapper:nth-child(2) { transition-delay: 400; transform: translateX(-144px); }
header#app-header .action-icons.__show .action-item-wrapper:nth-child(1) { transition-delay: 500; transform: translateX(-192px); }

header#app-header .action-icons .action-item-wrapper:nth-child(5) { transition-delay: 500; }
header#app-header .action-icons .action-item-wrapper:nth-child(4) { transition-delay: 400; }
header#app-header .action-icons .action-item-wrapper:nth-child(3) { transition-delay: 300; }
header#app-header .action-icons .action-item-wrapper:nth-child(2) { transition-delay: 200; }
header#app-header .action-icons .action-item-wrapper:nth-child(1) { transition-delay: 100; }


header#app-header .action-icons .action-item-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0;
    transform: translateX(0);
    pointer-events: none;
    transition: 300ms ease;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

header#app-header .action-icons.__show .action-item-wrapper {
    opacity: 1;
    pointer-events: unset;
    -webkit-user-select: unset;
       -moz-user-select: unset;
        -ms-user-select: unset;
            user-select: unset;
}


header#app-header .action-icons .action-item-wrapper.notifications-action svg {
    color: #ffc009;
    font-size: 28px;
}

header#app-header .action-icons .action-item-wrapper.notifications-action .MuiBadge-anchorOriginTopRightRectangle {
    top: 7px;
    right: 4px;
    height: 14px;
    min-width: 14px;
}

header#app-header .header-wrapper .profile-button {
    width: 28px;
    height: 28px;
    min-width: 28px;
    max-width: 28px;
    border-radius: 50%;
    margin-right: 18px;
    padding-left: 14px;
    padding-right: 14px;
}

header#app-header .header-wrapper .profile-picture {
    width: 64px;
    height: 64px;
}

#page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 24px;
}

#page-header .left-side {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

#page-header .left-side .page-header-button {
  color: #3f526d;
  font-weight: 600;
  text-decoration: none;
  text-transform: none;
  font-size: 20px;
}

#page-header .left-side .page-header-button .go-back-icon {
  font-size: 22px;
}

#page-header .left-side .page-header-button .page-header-button-text {
  font-weight: 600;
  font-size: 19px;
}
#root > div {
    max-width: 100%;
}

#main-container {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-flow: row nowrap;
}

#main-container .content-area {
    flex: 1 1 auto;
    max-width: 100%;
    position: relative;
    min-width: 0;
}

#main-container .content-area .radius-background {
    position: absolute;
    left: -1px;
    top: 0;
    width: 40px;
    height: 40px;
    z-index: 1;
}

#main-container .content-area .radius-background-2 {
    position: absolute;
    left: -1px;
    bottom: 0;
    width: 40px;
    height: 40px;
    z-index: 1;
}

#main-container .content-area .content-area-wrapper {
    background-color: #fff;
    padding-left: 26px;
    padding-right: 26px;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    min-height: 100vh;
    min-height: calc(100vh - 33px);
    z-index: 2;
    position: relative;
}

#main-container.__fullwidth .content-area.content-area-wrapper {
    padding-right: unset;
}
.top-students-wrapper{
    flex:0 0 250px;
    max-width:250px;
    margin-right:-26px;
    margin-left:40px;
    overflow:hidden;
}
.top-students-menu-container{
    height:100%;
    padding: 30px 5px 40px 40px;
    border-top-left-radius: 40px;
    overflow:hidden;
}

.top-students-menu-container h3,
.top-students-card h4,
.top-students-card span{
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin:0;
}

.top-students-menu-container h3{
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    margin-bottom:36px;
}

.top-students-card{
    display:flex;
    align-items:center;
    grid-gap:15px;
    gap:15px;
    margin-bottom:28px;
}

.top-students-card img{
    width:46px;
    height:46px;
    border-radius:50%;
    border: solid 2px #fff;
}

.top-students-card h4{
    font-size: 16px;
    font-weight: 600;
    color: #fff;
}

.top-students-card span{
    font-size: 12px;
    font-weight: normal;
    color: rgba(255, 255, 255, 0.76);
}

.border-colored-text-field-wrapper{
    position:relative;
    display:flex;
    align-items:center;
    width:100%;
}

.border-colored-text-field-container .border-colored-text-field-icon{
    position:absolute;
    right:20px;
}

.student-search-form{
    margin-bottom:20px;
}

.student-search-input{
    border-radius:6px!important;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(63, 82, 109,0.5)!important;
    width:100%;
    padding:15px 55px 15px 15px;
    border-style:solid;
}

.student-search-input input{
    padding:18px 20px!important;
}

.border-colored-text-field-container .border-colored-text-field-icon img.student-search-icon{
    width:20px;
    height:20px;
    margin-right:5px;
}


.friend-list-block .user-list-wrapper .user-list-item {
    padding: 10px;
}
.friend-list-block .user-list-wrapper .user-list-item-card {
    border: 1px solid rgba(63, 82, 109, 0.5);
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 20px 16px 14px 16px;
    background-color: #fff;
    text-transform: unset;
    width: 100%;
}

.friend-list-block .friend-list-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    padding: 40px 0;
    flex-wrap: wrap;
    height: calc(100% - 160px);
}

.friend-list-block .friend-list-placeholder span {
    flex: 1 1 auto;
    width: 100%;
    max-width: 100%;
    text-align: center;
    margin-top: 80px;
    color: #3f526d;
    font-size: 14px;
}

.friend-list-block .student-list-wrapper {
    position: relative;
    padding-bottom: 25px;
}

.friend-list-block .pagination-wrapper {
    position: absolute;
    right: 0;
    bottom: 25px;
}

.custom-pagination .MuiPagination-ul li:not(:last-child) {
  margin-right: 12px;
}

.custom-pagination .MuiPagination-ul li .page-button {
  width: 44px;
  height: 44px;
  font-size: 16px;
  min-width: unset;
}

.custom-pagination .MuiPagination-ul li .previousButton,
.custom-pagination .MuiPagination-ul li .nextButton {
  height: 44px;
  width: 124px;
  font-size: 16px;
  text-transform: unset;
}

.custom-pagination .MuiPagination-ul li .previousButton svg,
.custom-pagination .MuiPagination-ul li .nextButton svg {
  font-size: 24px;
}

.custom-pagination .MuiPagination-ul li .nextButton svg {
  transform: rotate(180deg);
}

.custom-pagination .MuiPagination-ul li button:disabled {
  background-color: #fff;
  border: 1px solid #3a60d7;
  opacity: 0.4;
  color: #3f526d;
}
.generic-card {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1)!important;
    padding: 20px;
    border-radius: 8px;
  }
  
  .generic-card .generic-card-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  
  .generic-card .generic-card-subtitle {
    color: #3f526d;
    font-weight: 600;
    font-size: 18px;
  }
  
  .generic-card .card-divider {
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: rgba(63, 82, 109, 0.12);
  }

.avatar-field {
    padding: 3px;
    background-color: #f9f8fd;
    border-width: 2px;
    border-style: solid;
    margin-right: 15px;
}
* {
    font-family: Open Sans,sans-serif;
}

#mark-all-notifications-as-read-button {
    text-transform: none;
    font-weight: 600;
    font-size: 16px;
    height: 44px;
}
  
#notifications-page .search-area .search-area-textfield {
    height: 60px;
    width:100%;
    border-radius:8px;
    border-style:solid;
    border-width:1px;
    padding:0 55px 0 20px;
}
  
#notifications-page .search-area .search-area-textfield .MuiInputBase-formControl {
    font-size: 18px;
}
  
#notifications-page .search-area .search-area-textfield .MuiInputBase-formControl input::-moz-placeholder {
    font-weight: 600;
}
  
#notifications-page .search-area .search-area-textfield .MuiInputBase-formControl input:-ms-input-placeholder {
    font-weight: 600;
}
  
#notifications-page .search-area .search-area-textfield .MuiInputBase-formControl input::placeholder {
    font-weight: 600;
}

#notifications-page .search-area .search-icon {
    font-size: 34px;
}
  
#notifications-page .notification-list {
    margin: 20px 0 40px 0;
}
  
#notifications-page .notification-list .notification-group {
    margin-top: 20px;
}
  
#notifications-page .notification-list .notification-group .group-title {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
}
  
#notifications-page .notification-list .notification-group .group-title .group-title-text{
    font-size: 18px;
    font-weight: 600;
    color: #3f526d;
}
  
#notifications-page .notification-list .notification-group .group-title .clear-all-button {
    font-size: 18px;
    padding-right: 0;
    text-decoration: underline;
    text-transform: unset;
    font-weight: 600;
}
  
#notifications-page .notification-list .item-list {
    
}
  
#notifications-page .notification-list .item-list .notification-item:not(:last-child) {
    margin-bottom: 10px;
}
  
#notifications-page .notification-list .item-list .notification-item {
    border-radius: 6px;
    padding: 14px 20px;
    position: relative;
    background-color: #f9f8fd;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-avatar {
    margin-top: 11px;
}
  
  /* CONTENTS */
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section {
    padding-left: 20px;
    display: flex;
    flex-wrap: nowrap;
    flex: auto;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area {
    flex: auto;
    padding-right: 37px;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area strong {
    font-weight: 600;
    font-size: 18px;
    color: #3f526d;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #3f526d;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area p mark {
    background-color: unset;
    color: #3a60d7;
}
  
  /* ATTACHMENTS */
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .attachments {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 -10px;
    margin-bottom: -10px;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .attachments .attachment-item {
    padding: 10px;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .attachments a{
    text-decoration: none;
  
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .attachments button {
    color: #327095;
    text-transform: none;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .attachments .filename {
    font-size: 16px;
    font-weight: 600;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .attachments button .file-size {
    display: block;
    font-size: 12px;
    color: #79869f;
    text-align: left;
    font-weight: normal;
}
  
  /* Date and Remove Section */
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .date-and-remove-area b{
    color: #3f526d;
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
    position: relative;
    top: -4px;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .date-and-remove-area .remove-button {
    position: absolute;
    right: 9px;
    bottom: 6px;
}
  
#notifications-page .pagination-wrapper {
    display: flex;
    justify-content: flex-end;
}
#main-container.all-stories-page-main-container {
  height: 100vh;
}

#all-stories-page>*>.generic-card {
  height: 100%;
}

#all-stories-page .all-stories-sider {
  flex: 0 0 290px;
  max-width: 290px;
  width: 290px;
  margin-right: 32px;
  height: 100vh;
  height: calc(100vh - 180px);
  max-height: 100%;
}

#all-stories-page .all-stories-sider .generic-card {
  height: 100%;
}

#all-stories-page .all-stories-sider .generic-card .card-divider {
  margin-bottom: 12px;
}

#all-stories-page .all-stories-sider .generic-card-content {
  margin: 0 -20px;
  height: calc(100% - 40px);
  overflow-y: auto;
}

#all-stories-page .all-stories-story-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 20px;
  cursor: pointer;
  text-transform: none;
  width: 100%;
  border: 0;
  background-color: #fff;
}

#all-stories-page .all-stories-story-item.__active {
  background-color: #f9f8fd;
}

#all-stories-page .all-stories-story-item:hover {
  /* background-color: rgba(0, 0, 0, 0.17); */
}

#all-stories-page .all-stories-story-item:not(:first-child) {
  margin-top: 14px;
}

#all-stories-page .all-stories-story-item .texts {
  text-align: left;
}

#all-stories-page .all-stories-story-item .texts strong {
  display: block;
  font-weight: 600;
  font-size: 18px;
  color: #3f526d;
}

#all-stories-page .all-stories-story-item .texts small {
  font-size: 12px;
  color: rgba(63, 82, 109, 0.6);
  font-weight: 600;
}

#all-stories-page .all-stories-content {
  flex: auto;
  max-width: 100%;
}

#all-stories-page .all-stories-content .active-stories-content-wrapper .generic-card-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  height: calc(100% + 0px);
}

#all-stories-page .all-stories-content .active-stories-content-wrapper .story-viewer-container {
  width: 500px;
  height: 100%;
  margin: 20px 30px;
  max-width: 100%;
}

#all-stories-page .all-stories-content .active-stories-content-wrapper .back-button,
#all-stories-page .all-stories-content .active-stories-content-wrapper .next-button {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.13);
}

#all-stories-page .all-stories-content .active-stories-content-wrapper .next-button {
  transform: rotate(180deg);
}

#all-stories-page .all-stories-content .active-stories-content-wrapper .story-viewer-container .story-viewer-wrapper {
  flex: auto;
}

#all-stories-page .all-stories-content .active-stories-content-wrapper .story-viewer-container .story-viewer-wrapper .story-viewer {
  height: 100%;
  padding: 0;
}

#all-stories-page .all-stories-content .active-stories-content-wrapper .story-viewer-container .story-viewer-wrapper .story-viewer .story-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
}

#all-stories-page .all-stories-content .active-stories-content-wrapper .story-viewer-container .story-viewer-wrapper .story-viewer .story-wrapper .story-head {
  position: absolute;
  left: 0;
  top: 0;
  padding: 14px 10px;
  width: 100%;
}

#all-stories-page .all-stories-content .active-stories-content-wrapper .story-viewer-container .story-viewer-wrapper .story-viewer .story-wrapper .story-content {
  background-size: contain;
  width: 100%;
  height: 100%;
  height: calc(100% - 100px);
  background-repeat: no-repeat;
  background-position: center center;
}

#all-stories-page .all-stories-content .active-stories-content-wrapper .story-viewer-container .story-viewer-wrapper .story-viewer .story-wrapper .favorite-btn {
  position: absolute;
  right: 8px;
  bottom: 8px;
}

#all-stories-page .all-stories-content .active-stories-content-wrapper .story-viewer-container .story-viewer-wrapper .story-viewer .story-wrapper .favorite-btn svg {
  font-size: 28px;
}

/* #all-stories-page .all-stories-content .active-stories-content-wrapper .story-viewer-container .story-viewer-wrapper .story-viewer .story-wrapper .story-content img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
  } */

/* STORY NAVS */

#all-stories-page .all-stories-content .active-stories-content-wrapper .story-viewer-container .story-viewer-wrapper .story-viewer .story-wrapper .story-navs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  margin: 0 -6px;
  width: 100%;
}

#all-stories-page .all-stories-content .active-stories-content-wrapper .story-viewer-container .story-viewer-wrapper .story-viewer .story-wrapper .story-navs .story-bar {
  flex: auto;
  max-width: 100%;
  height: 6px;
  background-color: #d8d8d8;
  opacity: 0.3;
  margin: 0 6px;
  max-width: 110px;
  border-radius: 3px;
}

#all-stories-page .all-stories-content .active-stories-content-wrapper .story-viewer-container .story-viewer-wrapper .story-viewer .story-wrapper .story-navs .story-bar.__active {
  opacity: 1;
}

/* STORY OWNER */
#all-stories-page .all-stories-content .active-stories-content-wrapper .story-viewer-container .story-viewer-wrapper .story-viewer .story-wrapper .story-owner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}

#all-stories-page .all-stories-content .active-stories-content-wrapper .story-viewer-container .story-viewer-wrapper .story-viewer .story-wrapper .story-owner .avatar {
  width: 44px;
  height: 44px;
  margin-right: 14px;
}

#all-stories-page .all-stories-content .active-stories-content-wrapper .story-viewer-container .story-viewer-wrapper .story-viewer .story-wrapper .story-owner h6 {
  color: #3f526d;
  font-size: 16px;
  font-weight: 600;
}

/* REPLY SECTION */

#all-stories-page .all-stories-content .active-stories-content-wrapper .story-viewer-container .reply-wrapper {
  margin-top: 30px;
}

#all-stories-page .all-stories-content .active-stories-content-wrapper .story-viewer-container .reply-wrapper .textarea input {
  height: 46px;
  font-size: 14px;
  padding: 0 20px;
  font-weight: 600;
}

#all-stories-page .all-stories-content .active-stories-content-wrapper .story-viewer-container .reply-wrapper .textarea fieldset {
  border-radius: 25px;
}

#all-stories-page .all-stories-content .active-stories-content-wrapper .story-viewer-container .reply-wrapper .textarea .reply-area-smile-button {
  margin-right: -12px;
}

#all-stories-page .all-stories-content .active-stories-content-wrapper .story-viewer-container .reply-wrapper .textarea .reply-area-smile-button svg {
  opacity: 0.48;
}

#all-stories-page .emoji-textarea-container {
  position:relative;
  display:flex;
  align-items:center;
  width:100%;
}

#all-stories-page .emoji-textarea-container .textarea{
  height: 46px;
  font-size: 14px;
  padding: 0 20px;
  font-weight: 600;
  border-radius: 25px;
  outline:none;
  width:100%;
  border-width:1px;
  border-style:solid;
}
.add-file-dialog-body{
    display:flex;
    flex-direction:column;
    margin:10px 21px;
}

.add-file-dialog-body img{
    width:70px;
}

.add-file-dialog-title{
    font-size: 18px;
    font-weight: 600;
    color:#3f526d;
    margin:0;
}

.add-file-dropzone-container{
    /*padding: 25px 255px 67px 91px;*/
    border-radius: 8px;
    border: dotted 0.5px rgba(37, 56, 115, 0.5);
    background-color: #f9f8fd;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    padding:25px 92px 20px 93px;
    margin-top:20px;
    margin-bottom:40px;
}
/*git commit -m "[finishes #798344] Add AnswerDialog | ShareToFeed | SendToChat dialogs, Badges tab data, discussion forum tab data design, some improvements on components, Add functionality to add comments"*/
.add-file-dropzone-container p{
    opacity: 0.5;
    font-size: 16px;
    color: #253873;
    margin-top:25px;
}

.add-file-dialog-body button{
    text-transform:none!important;
    line-height:normal!important;
}

.add-file-button{
    font-size: 16px!important;
    font-weight: 600!important;
    padding:13px 39px!important;
    border-radius:8px!important;
}

.add-file-upload-buttons{
    display:flex;
    grid-gap:10px;
    gap:10px;
    margin-bottom:22px;
}

.upload-file-button, .cancel-file-button{
    font-size: 18px!important;
    font-weight: 600!important;
    padding:15px 48px!important;
    border-radius:8px!important;
}

.cancel-file-button{
    color:#3f526d!important;
}

.file-list{
    margin-bottom:40px;
}

.file-list-item{
    display:flex;
    justify-content: space-between;
    align-items:center;
}

.file-list-item div{
    display:flex;
    align-items:center;
}

.file-list-item .file-name,
.file-list-item .file-upload-time{
    font-family: OpenSans,sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
}

.file-list-item .file-name{
    font-size: 18px;
    margin-left:20px;

}

.file-list-item .file-upload-time{
    opacity: 0.6;
    font-size: 14px;
    margin-right:18px;
}

.file-list-item .file-icon{
    width:32px;
    height:32px;
}

.dialog-container .MuiDialogContent-root{
    padding:0
}

.dialog-container .MuiPaper-root{
    border-radius:12px!important;
}

.close-dialog-button{
    position:absolute!important;
    top:0;
    right:0;
}

.download-dialog-button{
    position:absolute!important;
    top:30px;
    right:30px;
    border-radius:6px!important;
    border: solid 0.5px rgba(63, 82, 109, 0.24)!important;
    width:44px;
    height:44px;
}

.dialog-container .MuiBackdrop-root{
    background-color:rgba(0, 0, 0, 0.25);
}

.download-dialog-button img{
    width:14px;
}

.portfolio-body{
    padding:40px 60px 60px 60px;
    max-width:1000px;
}

.portfolio-header{
    display:flex;
    align-items:center;
    border-bottom:1px solid rgba(63, 82, 109, 0.28);
    padding-bottom:30px;
}

.portfolio-header .portfolio-image{
    height:182px;
    width:182px;
    margin-right:60px;
}

.verified-icon{
    height:18px;
    width:18px;
    margin-left:14px;
}

.portfolio-body h4,
.portfolio-body h3,
.portfolio-body p,
.portfolio-body span{
    font-family: OpenSans,sans-serif;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin:0;
}

.portfolio-header h3{
    font-size: 46px;
    font-weight: 600;
    color:#3f526d;
    margin-bottom:2px;
}

.portfolio-header span:first-child{
    font-weight: 600;
    margin-bottom:15px;
}

.portfolio-header span:last-child,
.portfolio-header span:first-child{
    font-size: 24px;
    color:#3f526d;
    opacity:0.68;
}

.portfolio-information{
    display:flex;
    flex-direction:column;
    grid-gap:15px;
    gap:15px;
}

.portfolio-body h4{
    font-size: 20px;
    font-weight: 600;
    color:#3f526d;
    margin-top:36px;
}

.portfolio-body p{
    font-size: 20px;
    color:#3f526d;
    margin:0;
    padding:12px 26px 31px 0;
    border-bottom:1px solid rgba(63, 82, 109, 0.28);
    line-height:1.6;
}

.portfolio-body .user-summary-items{
    display:flex;
    justify-content: space-between;
    align-items:center;
    padding:31px 0;
    border-bottom:1px solid rgba(63, 82, 109, 0.28);
    grid-gap:26px;
    gap:26px;
}

.portfolio-body .summary-item{
    display:flex;
    align-items:center;
    border-radius: 10px;
    border: solid 0.3px #979797;
    background-color: #fff;
    width:25%;
    padding-left:15px;
    height:64px;
}

.portfolio-body .summary-item img{
    width:38px;
    height:38px;
    margin-right:16px;
    margin-left:5px;
}

.portfolio-body .summary-item-total,
.portfolio-body .summary-item-type{
    font-size:18px;
    color:#3f526d;
}

.portfolio-body .summary-item-total{
    font-weight:600;
}

.portfolio-body .summary-item-type{
    color:rgba(63, 82, 109, 0.5);
}

.portfolio-body .achievements-wrapper{
    display:flex;
    align-items:center;
    grid-gap:50px;
    gap:50px;
    padding-bottom:85px;
    border-bottom:1px solid rgba(63, 82, 109, 0.28);
}

.portfolio-body .achievement{
    display:flex;
    flex-direction:column;
    align-items:center;
    margin-top:19px;
}

.portfolio-body .achievement-text{
    font-size: 16px;
    font-weight: 600;
    opacity:0.74;
}

.achievement-image{
    height:120px;
    margin-top:20px;
}

.portfolio-body .goal-items{
    display:flex;
    flex-direction:column;
    grid-gap:10px;
    gap:10px;
}

.portfolio-body .goal-wrapper{
    border-radius: 8px;
    border: solid 0.3px #979797;
    padding:12px 20px 12px 30px;
    margin-top:20px;
}

.portfolio-body .goal-item{
    display:flex;
    align-items:center;
    justify-content: space-between;
}

.portfolio-body .goal-text{
    display:flex;
    align-items:center;
}

.portfolio-body .goal-text img{
    width:18px;
    height:18px;
    margin-right:18px;
}

.portfolio-body .goal-text span{
    font-size: 16px;
    font-weight: 600;
    color:#3f526d;
}

.portfolio-body .goal-item .due-date{
    border-radius: 6px;
    background-color: #f9f8fd;
    padding:8px 15px 7px 15px;
    display:inline-block;
}

.portfolio-body .goal-item .due-date span{
    font-family: OpenSans,sans-serif;
    font-size: 12px;
    text-align: center;
    color: #3f526d;
}

.portfolio-body .goal-item .due-date span:first-child{
    font-weight:600;
}

.portfolio-body .goal-completed{
    padding:8px 68px;
    border-radius:6px;
    background-color: #51ce32;
    color:#fff;
    font-size: 12px;
    font-weight: 600;
}

.portfolio-body .goal-picture-container{
    width:100%;
    display:flex;
    align-items:center;
}

.portfolio-body .goal-picture-container img{
    width:600px;
    margin:50px auto;
}


.box-controller-container{
    display:flex;
    align-items:center;
    grid-gap:18px;
    gap:18px;
    margin-bottom:24px;
}

.box-controller-item{
    height:48px;
    width:48px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    border-radius:8px;
    display:flex;
    align-items:center;
    justify-content: center;
    cursor:pointer;
}

.box-controller-item img{
    width:24px;
    height:24px;
}

.box-controller-item:last-child svg{
    font-size: 36px;
}
#target-group-wrapper .target-group-selectbox {
    color: #3f526d;
    border-radius: 6px!important;
    background-color: #f9f8fd;
}
  
#target-group-wrapper .target-group-selectbox > .MuiSelect-select{
    padding-top: 13px;
    padding-bottom: 12px;
}
  
#target-group-wrapper .target-group-selectbox.__small > .MuiSelect-select {
    padding-top: 10px;
    padding-bottom: 9px;
}
  
#target-group-wrapper .target-group-selectbox .selectbox-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    color: #3f526d;
    font-size: 14px;
    font-weight: 600;
}
  
#target-group-wrapper .target-group-selectbox .selectbox-wrapper .selectbox-icon {
    margin-right: 10px;
    font-size: 14px;
    color: #3f526d;
    font-size: 16px;
}

.target-group-menu-item .menu-item-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: row nowrap;
} 


.target-group-menu-item .menu-item-wrapper .menu-icon {
    opacity: 0.5;
    margin-right: 8px;
} 

.target-group-menu-item .menu-item-wrapper .texts b {
    display: block;
    font-size: 14px;
}

.target-group-menu-item .menu-item-wrapper .texts small {
    font-size: 12px;
    opacity: 0.5;
}

#create-content-footer-section-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row wrap;
}

#create-content-footer-section-wrapper .left-side {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
}

#create-content-footer-section-wrapper .left-side .section-item {
    font-size: 14px;
    text-transform: none;
    color: #3f526d; 
    font-weight: normal;
}

#create-content-footer-section-wrapper .left-side hr {
    margin: 0 20px;
    height: 22.5px;
    align-self: center;
}

#create-content-footer-section-wrapper .left-side .section-item img {
    margin-right: 6px;
}

#create-content-footer-section-wrapper .right-side {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
}

#create-content-footer-section-wrapper .right-side .action-button {
    margin-left: 14px;
    text-transform: none;
    min-width: 100px;
    border-radius: 6px;
}
  

#gallery-view .top-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding-right: 8px;
}
  
#gallery-view .done-button {
    width: 36px;
    flex: 0 0 36px;
    max-width: 36px;
    min-width: unset;
}
  
#gallery-view .gallery-divider {
    margin: 20px 0;
}
  
#gallery-view .images-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: -8px;
}
  
#gallery-view .images-wrapper .gallery-view-item {
    margin-right: 8px;
    margin-bottom: 8px;
    color: white;
    position: relative;
    width: calc(33.3% - 8px);
}
  
#gallery-view .images-wrapper .gallery-view-item > span {
    width: 100%;
    height: 100%;
}
  
#gallery-view .images-wrapper .gallery-view-item img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
}
  
#gallery-view .images-wrapper .gallery-view-item .overlay {
    position: absolute;
    left:0;
    top: 0;
    width: 100%;
    height: 100%;
    font-size: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
  
#gallery-view .images-wrapper .gallery-view-item .overlay .overlay-icon {
    color: #fff;
    font-size: 100px;
    text-shadow: rgba(0, 0, 0, 0.17);
}
#capture-photo-view {

}

#capture-photo-view .camera video {
  max-width: 100%;
}

#capture-photo-view .take-photo-button {
  margin: 0 auto;
  display: block;
  margin-top: 30px;
  width: 280px;
  max-width: 100%;
  text-transform: unset;
  font-weight: 600;
}

#capture-photo-view #canvas{
  display: none;
}

.add-to-dialog-wrapper {
    width: 529px;
    max-width: 100%;
}

.add-to-dialog-wrapper[data-view="GALLERY"],
.add-to-dialog-wrapper[data-view="CAMERA"] {
    width: 900px;
}

.add-to-dialog-wrapper .dialog-title {
    text-align: center;
    margin-top: -6px;
}

.add-to-dialog-wrapper .dialog-title h6 {
    color: #3f526d;
    font-size: 18px;
    font-weight: bold;
}

.add-to-dialog-wrapper .dialog-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    min-height: 240px;
    padding: 20px 50px;
}

.add-to-dialog-wrapper .dialog-content .add-to-dialog-option-button {
    text-transform: unset;
    width: 170px;
    max-width: 170px;
}

.add-to-dialog-wrapper .dialog-content .add-to-dialog-option-button > .MuiButton-label{
    flex-wrap: wrap;
}

.add-to-dialog-wrapper .dialog-content .add-to-dialog-option-button > .MuiButton-label .MuiButton-startIcon {
    margin-left:0;
    margin-right: 0;
}

.add-to-dialog-wrapper .dialog-content .add-to-dialog-option-button .button-text {
    flex: 1 1 100%;
    max-width: 100%;
    margin-top: 16px;
    color: rgba(63, 82, 109, 0.6);
    line-height: 30px;
    font-weight: 600;
}

/* Textarea */

#create-content-widget {
    margin-bottom: 24px;
}

#create-content-widget .widget-title{
    font-size: 14px;
    font-weight: 600;
    color: #3f526d;
    margin-bottom: 14px;
    }

#create-content-widget .widget-content .textarea {
}

#create-content-widget .widget-content .textarea textarea {
    font-size: 14px;
    font-weight: 600;
    color: #3f526d;
}

#create-content-widget .widget-content .textarea textarea::-moz-placeholder {
    font-size: 14px;
    font-weight: 600;
    color: rgba(63, 82, 109, 0.68);
}

#create-content-widget .widget-content .textarea textarea:-ms-input-placeholder {
    font-size: 14px;
    font-weight: 600;
    color: rgba(63, 82, 109, 0.68);
}

#create-content-widget .widget-content .textarea textarea::placeholder {
    font-size: 14px;
    font-weight: 600;
    color: rgba(63, 82, 109, 0.68);
}

#create-content-widget .widget-content .textarea .mui-textarea-wrapper{
    padding: 22px 23px;
    min-height: 86px;
    border-radius: 8px;
    margin-bottom: 40px;
    background-color: #f9f8fd;
}

#create-content-widget .widget-content#add-to-badge .textarea .mui-textarea-wrapper{
    min-height: 59px;
    margin-bottom: 30px;
}


#create-content-widget .widget-content .textarea .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
    margin-top: 0;
}

#create-content-widget .widget-content .textarea .avatar {
    width: 42px;
    height: 42px;
    margin-right: 15px;
}


/* CREATE CONTENT DIALOG */
.create-content-dialog-wrapper {
    width: 640px;
    padding: 20px 26px;
    padding-top: 0;
    max-width: 100%;
}

.create-content-dialog-wrapper .dialog-title {
    font-size: 18px;
    color: #3f526d;
    font-weight: 600;
}

.create-content-dialog-wrapper .dialog-content {
}

.create-content-dialog-wrapper .dialog-content .personal-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 30px;
    margin-bottom: 26px;
}

.create-content-dialog-wrapper .dialog-content .personal-info .avatar {
    margin-right: 14px;
}

.create-content-dialog-wrapper .dialog-content .personal-info strong {
    font-size: 16px;
    font-weight: 600;
    color: #3f526d;
}

.create-content-dialog-wrapper .dialog-content .textarea textarea {
    font-size: 16px;
    color: #3f526d;
}

.create-content-dialog-wrapper .dialog-content .textarea textarea::-moz-placeholder {
    color: rgba(63, 82, 109, 0.5);
}

.create-content-dialog-wrapper .dialog-content .textarea textarea:-ms-input-placeholder {
    color: rgba(63, 82, 109, 0.5);
}

.create-content-dialog-wrapper .dialog-content .textarea textarea::placeholder {
    color: rgba(63, 82, 109, 0.5);
}

.create-content-dialog-wrapper .dialog-content .image-list {
    border-width: 0.5px;
    border-style: solid;
    margin-top: 40px;
    border-radius: 8px;
    position: relative;
    background-color: #f9f8fd;
    padding: 7px;
}

.create-content-dialog-wrapper .dialog-content .image-list .image-item {
    padding: 6px;
    position: relative;
}

.create-content-dialog-wrapper .dialog-content .image-list.__one-item .image-item {
    width: 100%;
    height: 256px;
}

.create-content-dialog-wrapper .dialog-content .image-list.__one-item .image-item img{
    -o-object-fit: contain!important;
       object-fit: contain!important;
}

.create-content-dialog-wrapper .dialog-content .image-list.__two-items {
    display: grid;
    grid-template-columns: 50% 50%;
}

.create-content-dialog-wrapper .dialog-content .image-list.__two-items .image-item {
    width: 100%;
    height: 256px;
}


.create-content-dialog-wrapper .dialog-content .image-list.__three-items {
    display: grid;
    grid-template-columns: 50% 50%;
}


.create-content-dialog-wrapper .dialog-content .image-list.__three-items .image-item:nth-child(1) {
    grid-row: span 2;
    height: 256px;
}

.create-content-dialog-wrapper .dialog-content .image-list.__three-items .image-item:nth-child(2) {
    grid-row: span 1;
    height: 128px;
}

.create-content-dialog-wrapper .dialog-content .image-list.__three-items .image-item:nth-child(3) {
    grid-row: span 1;
    height: 128px;
}

.create-content-dialog-wrapper .dialog-content .image-list.__higher-than-three-items .images-count {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    text-align: center;
    font-size: 20px;
    letter-spacing: 0;
    position: absolute;
    left:0;
    top:0;
    background-color: rgba(0,0,0,0.35);
    width: 100%;
    width: calc(100% - 12px);
    height: 100%;
    height: calc(100% - 12px);
    margin-left: 6px;
    margin-top: 6px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    pointer-events: none;
    border-radius: 8px;
    overflow: hidden;
    color: #fff;
}

.create-content-dialog-wrapper .dialog-content .image-list .image-item img {
    width: 100%;
    height: 100%;
    cursor: pointer;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 8px;
}

.create-content-dialog-wrapper .dialog-content .image-list.__two-items .image-item {

}

.create-content-dialog-wrapper .dialog-content .closeButton {
    position: absolute;
    right: 5px;
    top: 5px;
    min-width: unset;
}

.create-content-dialog-wrapper .dialog-content .closeButton svg {
    color: #fff;
}

.create-content-dialog-wrapper #create-content-footer-section-wrapper {
    margin-top: 20px;
}
#image-carousel-dialog .MuiDialog-paper{
    background-color: transparent;
    max-width: 80vw;
    box-shadow: none;
    width: 100%;
    position: relative;
    overflow-y: visible;
}

#image-carousel-dialog .dialog-container{
    padding-top: 0;
    overflow-y: visible;
}

#image-carousel-dialog .remove-image-button {
    position: absolute;
    right:140px;
    top:0px;
    transform: translateY(-100%);
}

#image-carousel-dialog .remove-image-button svg {
    font-size: 35px;
    color: #fff;
}

#image-carousel-dialog .close-dialog-button {
    position: absolute;
    right: 90px;
    top:0;
    transform: translateY(-100%);
}

#image-carousel-dialog .close-dialog-button svg {
    font-size: 35px;
    color: #fff;
}

#image-carousel-dialog .carousel-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
}

#image-carousel-dialog .back-button svg,
#image-carousel-dialog .next-button svg {
    font-size: 44px;
}

#image-carousel-dialog .back-button:not(.Mui-disabled) svg,
#image-carousel-dialog .next-button:not(.Mui-disabled) svg {
    color: #fff;
    font-size: 44px;
}

#image-carousel-dialog .next-button svg {
    transform: rotate(180deg);
}

#image-carousel-dialog .images-wrapper {
    margin-left: 40px;
    margin-right: 40px;
    flex: 1 1 auto;
    max-width: 100%;
}

#image-carousel-dialog .images-wrapper .image-wrapper {
    width: 100%;
    height: 60vh;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.single-dot{
    width: 2px;
    height: 2px;
    margin: 0 12px;
    background-color: #3f526d;
}

.single-post{
    margin-bottom:24px;
}

.boxed-view-container{
    max-width:33.33333333%;
    flex-basis:33.33333333%;
    flex-grow:0;
    box-sizing:border-box;
    padding:11px;
    margin-bottom:0;
}

.list-view-container{
    max-width:100%;
    flex-basis:100%;
    flex-grow:0;
    box-sizing:border-box;
    padding:11px;
    margin-bottom:0!important;
}

.badges-container{
    max-width:50%;
    flex-basis:50%;
    flex-grow:0;
    box-sizing:border-box;
    padding:11px;
    margin-bottom:0;
}

@media (max-width:1400px){
    .boxed-view-container{
        max-width:50%;
        flex-basis:50%;
    }
}

.boxed-view{
    /*min-width:270px;*/
}

.post-image{
    width: 34px;
    height: 34px;
    border-radius:50%;
    -o-object-fit: contain;
       object-fit: contain;
    margin-right:10px;
}

.single-post-container{
    padding: 19px 30px 20px 30px;
}

.boxed-view .single-post-container{
    padding:20px 16px 14px 14px;
}

.single-post-container h4{
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    color: #3f526d;
    margin:0;
}

.single-post-container span{
    color: #3f526d;
    font-size: 12px;
    opacity: 0.68;
}

.single-post-wrapper{
    position:relative;
}

.more-vertical{
    color:#3f526d;
    opacity:0.24;
    position:absolute!important;
    top:5px;
    right:0;
}

.post-description{
    margin-top:17px;
}

.post-description p{
    font-size: 16px;
    line-height: 1.63;
    margin-bottom:20px;
    color:#3f526d;
}

.boxed-view .post-description p.box-view-description{
    min-height:90px;
}

.boxed-view .post-description p.box-view-description,
.list-view .post-description p.box-view-description{
    font-size:14px;
    line-height:1.57;
    margin-top:20px;
    margin-bottom:18px;
}

.post-description p.goal-text{
    font-weight:600;
}

.post-description p.video-text{
    margin-bottom:0;
}

.single-post-container hr{
    border-color:rgba(63, 82, 109, 0.12);
    border-width:1px;
}

.file-box{
    margin-bottom:20px!important;
}

.download-wrapper{
    text-decoration:none;
}

.file-box span{
    font-size: 18px;
    font-weight: 600;
    color: #3f526d;
    margin-left:16px;
    opacity:1;
}

.boxed-view .file-box span,
.list-view .file-box span{
    margin-left:10px;
}

.file-box .file-icon{
    width:38px;
    height:38px;
}

.boxed-view .file-box .file-icon,
.list-view .file-box .file-icon{
    width:20px;
    height:20px;
}

.file-box .download-icon{
    width:18px;
    height:18px;
}

.boxed-view .file-box .download-icon,
.list-view .file-box .download-icon{
    width:12px;
    height:12px;
    margin-right:8px;
}

.post-pictures{
    margin-bottom:20px!important;
    display:flex;
    align-items:stretch;
    flex-wrap:wrap;
    grid-gap:12px;
    gap:12px;
}

.post-pictures .first-half, .post-pictures .second-half{
    max-height:256px;
    flex:1 1;
    width:100%;
    border-radius:8px;
    -o-object-fit:cover;
       object-fit:cover
}

.post-pictures .half-height{
    max-height:122px;
    width:100%;
    flex:1 1;
    border-radius:8px;
    -o-object-fit:cover;
       object-fit:cover
}

.post-pictures-second-half{
    display:flex;
    flex-direction: column;
    flex:1 1;
    grid-gap:12px;
    gap:12px;
}

.post-pictures .full-size{
    width:100%;
    max-height:256px;
}

.post-link{
    font-size: 16px!important;
    color: #3a60d7!important;
    margin-bottom:11px!important;
}

.goal-picture,.video-picture{
    width:100%;
}

.video-picture-container{
    position:relative;
    cursor:pointer;
    margin-bottom:20px;
    width:100%;
    aspect-ratio:16/9;
}

.video-picture{
    border-radius:8px;
    height:100%;
}

.video-icon{
    position:absolute;
    margin:auto;
    left:0;
    right:0;
    top:0;
    bottom:0;
    width:50px;
    height:50px;
}

.single-post-container .due-date{
    border-radius: 6px;
    background-color: #f9f8fd;
    padding:8px 15px 7px 15px;
    margin-bottom:22px;
    display:inline-block;
}

.single-post-container .due-date span{
    font-size: 12px;
    text-align: center;
    color: #3f526d;
}

.single-post-container .due-date span:first-child{
    font-weight:600;
}

.menu-list img{
    margin-right:22px;
    width:20px;
}

.menu-list li{
    font-size: 16px;
    color:#3f526d;
}

.boxed-view{
    /*max-width:33.33%;*/
    /*flex:0 0 33%;*/
    width:100%;
}

.boxed-view span.file-text,
.list-view span.file-text{
    font-size:14px;
}

.badges-container .goal-picture{
    max-height:160px;
    -o-object-fit:contain;
       object-fit:contain;
    margin-bottom:30px;
    margin-top:20px;
}

.questions-container p:first-child{
    font-size:14px;
}

.questions-container .answer-text{
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(63, 82, 109, 0.6);
}

.questions-container .answer-text:hover{
    text-decoration:underline;
    cursor:pointer;
}

.single-post-container .comments{
    display:flex;
    flex-direction:column;
    margin-top:20px;
}

.single-post-container .load-more-button{
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
    text-transform:unset!important;
    margin-top:20px;
    max-width:200px;
    margin-left:20px;
}

.single-post-container .load-more-button .MuiButton-label{
    opacity:1!important;
}

.single-post-container .load-more-button img{
    width:8px;
    height:16px;
}

.replies .comment-container{
    background-color:#fff;
}

.create-content-dialog-wrapper .dialog-content .image-list {
    border-width: 0.5px;
    border-style: solid;
    margin-top: 40px;
    border-radius: 8px;
    position: relative;
    background-color: #f9f8fd;
    padding: 7px;
}

.single-post-container .image-list .image-item {
    padding: 6px;
    position: relative;
}

.single-post-container .image-list.__one-item .image-item {
    width: 100%;
    height: 256px;
}

.single-post-container .image-list.__one-item .image-item img{
    -o-object-fit: contain!important;
       object-fit: contain!important;
}

.single-post-container .image-list.__two-items {
    display: grid;
    grid-template-columns: 50% 50%;
}

.single-post-container .image-list.__two-items .image-item {
    width: 100%;
    height: 256px;
}


.single-post-container .image-list.__three-items {
    display: grid;
    grid-template-columns: 50% 50%;
}


.single-post-container .image-list.__three-items .image-item:nth-child(1) {
    grid-row: span 2;
    height: 256px;
}

.single-post-container .image-list.__three-items .image-item:nth-child(2) {
    grid-row: span 1;
    height: 128px;
}

.single-post-container .image-list.__three-items .image-item:nth-child(3) {
    grid-row: span 1;
    height: 128px;
}

.single-post-container .images-count span {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    text-align: center;
    font-size: 20px;
    letter-spacing: 0;
    position: absolute;
    left:0;
    top:0;
    background-color: rgba(0,0,0,0.35);
    width: 100%;
    width: calc(100% - 12px);
    height: 100%;
    height: calc(100% - 12px);
    margin-left: 6px;
    margin-top: 6px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    pointer-events: none;
    border-radius: 8px;
    overflow: hidden;
    color: #fff;
}

.single-post-container .image-item img {
    width: 100%;
    height: 100%;
    cursor: pointer;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 8px;
}

.create-post-box{
    grid-gap:14px;
    gap:14px;
    margin-top:20px!important;
    display:flex;
    align-items:center;
}

.create-post-box img{
    width:34px;
    height:34px;
}

.create-post-box form{
    display:flex;
    width:100%;
}

.create-post-box input{
    flex:1 1;
    width:100%;
    font-weight:600;
    padding: 13px 90px 13px 20px;
    position:relative;
    background-color:#f9f8fd;
    border-radius: 6px;
    border:none;
    min-height:18px;
}

.create-post-box input::-moz-placeholder {
    color:rgba(63, 82, 109, 0.2);
}

.create-post-box input:-ms-input-placeholder {
    color:rgba(63, 82, 109, 0.2);
}

.create-post-box input::placeholder {
    color:rgba(63, 82, 109, 0.2);
}

.create-post-box input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:rgba(63, 82, 109, 0.2);
}

.create-post-box input::-ms-input-placeholder { /* Microsoft Edge */
    color:rgba(63, 82, 109, 0.2);
}

.create-post-box-field{
    display:flex;
    align-items:center;
    width:100%;
    position:relative;
}

.create-post-box-field form{
    margin:0
}

.create-post-box-buttons{
    position:absolute;
    right:22px;
    display:flex;
    align-items:center;
    grid-gap:8px;
    gap:8px;
}

.create-post-box-buttons img{
    height:18px;
}

.create-post-box-button{
    height:20px;
    width:20px;
}

.post-interactions{
    grid-gap:30px;
    gap:30px;
}

.boxed-view .post-interactions{
    grid-gap:20px;
    gap:20px;
}

.post-interactions div{
    display:flex;
    align-items:center;
    cursor:pointer;
}

.post-interactions span{
    margin-left:12px;
    font-size:14px;
    color:#3f526d;
}

.boxed-view .post-interactions span:not(.MuiIconButton-label){
    margin-left:8px;
    font-size:12px;
}

.post-interactions img{
    height:18px;
    width:18px;
}

.share-button{
    padding:0!important;
}

.boxed-view .post-interactions svg{
    width:20px;
    height:20px;
}

.share-button:hover{
    background-color:transparent!important;
    text-align:left!important;
}

.share-button .MuiIconButton-label{
    margin:0;
}

.comments-dialog-body{
    display:flex;
    flex-direction:column;
    margin:1px 6px;
    min-width:300px;
    padding:8px 24px
}

.comments-dialog-title{
    font-size: 18px;
    font-weight: 600;
    color:#3f526d;
    margin:0 0 30px 0;
}

.comment-container{
    display:flex;
    background-color:#f9f8fd;
    border-radius:10px;
    margin-left:18px;
    padding:8px 10px;
}

.comment-container p{
    font-size: 10px;
    line-height: normal;
    color: #3f526d;
    margin:0;
}

.comment-text-container{
    /*margin-left:18px;*/
}

.comments-dialog-create-box-container{
    padding:0 34px 27px 30px;
}

.comment-box-divider{
    margin:0;
    height:1px;
    background-color:rgba(0, 0, 0, 0.46);
    width:100%;
    opacity:0.1;
}

.comment-box{
    display:inline-flex;
    margin-bottom:10px;
    width:100%;
}

.comment-box img{
    width:26px;
    height:26px;
    border-radius:50%;
}

.comment-box h5{
    font-size: 10px;
    line-height: normal;
    color: #3f526d;
    font-weight: 600;
    margin: 0 0 2px 0;
}

.add-answer-dialog{
    padding:4px 24px 24px 24px;
}

.add-answer-dialog-header{
    display:flex;
    align-items:center;
}

.add-answer-dialog-header img{
    width:34px;
    height:34px;
    border-radius:50%;
}

.add-answer-dialog-header h3{
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
    margin-left:12px;
}

.add-answer-dialog-body p{
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #3f526d;
    margin:20px 0 14px 0;
}

.add-answer-dialog textarea{
    border:none;
    resize:none;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(63, 82, 109, 0.36);
    width:100%;
}

.add-answer-dialog textarea::-moz-placeholder {
    color:rgba(63, 82, 109, 0.36);
}

.add-answer-dialog textarea:-ms-input-placeholder {
    color:rgba(63, 82, 109, 0.36);
}

.add-answer-dialog textarea::placeholder {
    color:rgba(63, 82, 109, 0.36);
}

.add-answer-dialog textarea:focus{
    outline:none;
}

.add-answer-actions{
    display:flex;
    justify-content:flex-end;
    align-items:center;
    grid-gap:8px;
    gap:8px;
    position:relative;
}

.add-answer-actions img{
    width:20px;
    height:20px;
}

.add-answer-dialog .vertical-line{
    height:22.5px;
    width:1px;
    background-color:rgba(63, 82, 109, 0.12);
}

.add-answer-button{
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    text-transform:capitalize!important;
}

.send-in-chat-body{
    max-width:640px;
}

.send-in-chat-header,.send-in-chat-recent{
    padding:0 26px 21px 26px;
}

.send-in-chat-title{
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
}

.send-in-chat-body .post-information{
    background-color:#f9f8fd;
    border-radius:8px;
}

.send-in-chat-post-box{
    display:flex;
    align-items:center;
    margin-top:20px;
}

.send-in-chat-post-box img{
    width:42px;
    height:42px;
}

.send-in-chat-post-box input{
    padding:10px 16px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
    opacity: 0.5;
    border:none;
    outline:none;
    width:100%;
}

.send-in-chat-divider{
    height: 1px;
    width:100%;
    background-color: rgba(63, 82, 109, 0.28);
}

.search-bar{
    border-radius: 22px;
    border-width: 1px;
    border-style: solid;
    padding-left:20px;
    padding-right:8px;
    display:flex;
    align-items:center;
    /*margin:19px 26px 0 26px;*/
    margin-top:19px;
    height: 44px;
}

.search-input{
    border:none;
    outline:none;
    height:100%;
    width:100%;
    padding-top:12px;
    padding-bottom:12px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color:#3f526d;
    opacity:0.4;
}

.search-button .search-icon{
    font-size: 27px;
}

.recent-title{
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
}

.recent-activity-item{
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin-bottom:18px;
}

.recent-information{
    display:flex;
    align-items:center;
}

.recent-information h3{
    margin-left:14px;
}

.send-recent-button{
    height:38px;
    text-transform:capitalize!important;
    padding:19px 33px!important;
    box-shadow:none!important;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    opacity: 0.7;
}

.send-recent-button:hover {
    opacity: 1;
}
.share-to-feed{
    max-width:640px;
    padding:0 26px 21px 26px;
}

.share-to-feed-title{
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
}

.share-to-feed .post-information{
    border-radius:8px;
    border-width: 0.5px;
    border-style: solid;
    background-color: #f9f8fd;
}

.share-to-feed-header{
    display:flex;
    align-items:center;
    margin-top:20px;
}

.share-to-feed-header img{
    width:42px;
    height:42px;
}

.share-to-feed-header h3{
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
    margin-left:14px;
}

.share-to-feed>input{
    padding:20px 16px 20px 0;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
    opacity: 0.5;
    border:none;
    outline:none;
    width:100%;
    margin-bottom:20px;
}

.post-button{
    width:100px;
    height:38px;
    text-transform:capitalize!important;
    padding:10px 35px!important;
    white-space: nowrap;
}

.share-to-feed .target-group-selectbox {
    background-color: rgba(0,0,0,0.05);
    height:38px;
    overflow:hidden;
}

.share-to-feed .single-post-container{
    padding:13px;
}

.post-actions-item,.share-to-feed .post-actions{
    display:flex;
    align-items:center;
}

.post-actions-item{
    grid-gap:14px;
    gap:14px;
}

.share-to-feed .post-actions{
    justify-content:space-between;
}

.share-to-feed .post-actions{
    margin:30px 13px 0 13px;
}

.post-actions-item:first-child img{
    width:20px;
    height:20px;
}

.post-actions-item:first-child .vertical-line{
    height: 22.5px;
    width: 1px;
    background-color: rgba(63, 82, 109, 0.12);
    margin:0 20px;
}

.post-actions-button,.post-button{
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-transform:capitalize!important;
    color: #3f526d;
}

.post-actions-button .MuiButton-startIcon{
    margin-right:14px;
}




.story-item{
    position:relative;
}

.story-item .main-image{
    width: 100px;
    height: 100px;
    border-radius: 8px;
    -o-object-fit: cover;
       object-fit: cover;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.2);
}

@media(max-width:1400px){
    .story-item .main-image{
        width:80px;
        height:80px;
    }
}

.story-item .mini-image{
    width: 26px;
    height: 26px;
    border-radius: 50%;
    -o-object-fit: contain;
       object-fit: contain;
    position:absolute;
    top:6px;
    left:6px;
    border:2px solid #7f6cfc;
}

.stories-header h3{
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    color: #3f526d;
    margin:0;
}

.stories-header button{
    font-size: 14px;
    font-weight:600;
    text-decoration: underline;
    text-transform: none;
}

.stories-container{
    margin-bottom:24px;
    margin-top:15px;
    grid-gap:30px;
    gap:30px;
}

/* CREATE STORY ITEM */
.create-story-button {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    width: 100px;
    height: 100px;
    border-radius: 8px!important;
    text-transform: none;
}

@media(max-width:1400px){
    .create-story-button{
        width:80px;
        height:80px;
    }
}

.create-story-button > .MuiButton-label {
    flex-wrap: wrap;
}

.create-story-button svg {
    font-size: 32px;
}

.create-story-button .add-to-story-text{
    display: block;
    font-size: 12px;
    font-weight: 600;
    flex: 1 1 100%;
    width: 100%;
    max-width: 100%;
    text-align: center;
    color: #3f526d;
    margin-top: 10px;
    text-transform: none;
}

/* Share Story Modal */

.share-story-wrapper {
    min-width: 320px;
    min-height: 200px;
    padding: 30px 50px;
    width: 70vw;
}

.share-story-wrapper .target-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.share-story-wrapper .target-group .target-group-selectbox{
    width: 140px;
}

.share-story-wrapper .story-images-wrapper {
    margin-top: 15px;
    margin-bottom: 15px;
}

.share-story-wrapper .story-images-wrapper .slider-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.share-story-wrapper .story-images-wrapper .slick-slide {
    padding: 0 11px;
}

.share-story-wrapper .story-images-wrapper .carousel-item {
    position: relative;
    background-color: rgba(208, 194, 163, 0.2);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    height: 500px;
    max-height: 100%;
}

.share-story-wrapper .story-images-wrapper .carousel-item .remove-button {
    position: absolute;
    right: 0;
    top: 0;
}

.share-story-wrapper .story-images-wrapper .slick-list {
    margin: 0 -20px;
    width: 100%;
}

.share-story-wrapper .story-images-wrapper .slick-prev {
    top: unset;
    transform: unset;
    -webkit-transform: unset;
    -moz-transform: unset;
    -ms-transform: unset;
    -o-transform: unset;
}

.share-story-wrapper .story-images-wrapper .slick-arrow {
    width: 56px;
    height: 56px;
}

.share-story-wrapper .story-images-wrapper .slick-arrow:before {
    content: ''!important;
    display: none;
}

.share-story-wrapper .story-images-wrapper .slick-arrow svg {
    font-size: 32px;
}

.share-story-wrapper .story-images-wrapper .slick-arrow:not(.slick-disabled) svg {
    color: #676767;
}

.share-story-wrapper .story-images-wrapper .slick-arrow.slick-disabled svg {
    color: #d3d3d3;
}

.share-story-wrapper .story-images-wrapper .next-arrow-icon {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
}

.share-story-wrapper .actions {
    display: flex;
    align-items: center;
    justify-content: center;
}

.share-story-wrapper .actions > button {
    text-transform: unset;
}

.share-story-wrapper .actions > button:first-child {
    margin-right: 10px;
    color: #3f526d;
    width: 170px;
    flex: 0 0 170px;
    max-width: 170px;
    background-color: #f9f8fd;
}

.share-story-wrapper .actions > button:last-child {
    margin-left: 10px;
    width: 200px;
    flex: 0 0 200px;
    max-width: 200px;
}

.slick-list,.slick-slider,.slick-track{position:relative;display:block}.slick-loading .slick-slide,.slick-loading .slick-track{visibility:hidden}.slick-slider{box-sizing:border-box;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-touch-callout:none;-khtml-user-select:none;touch-action:pan-y;-webkit-tap-highlight-color:transparent}.slick-list{overflow:hidden;margin:0;padding:0}.slick-list:focus{outline:0}.slick-list.dragging{cursor:pointer;cursor:hand}.slick-slider .slick-list,.slick-slider .slick-track{transform:translate3d(0,0,0)}.slick-track{top:0;left:0}.slick-track:after,.slick-track:before{display:table;content:''}.slick-track:after{clear:both}.slick-slide{display:none;float:left;height:100%;min-height:1px}[dir=rtl] .slick-slide{float:right}.slick-slide img{display:block}.slick-slide.slick-loading img{display:none}.slick-slide.dragging img{pointer-events:none}.slick-initialized .slick-slide{display:block}.slick-vertical .slick-slide{display:block;height:auto;border:1px solid transparent}.slick-arrow.slick-hidden{display:none}/*# sourceMappingURL=slick.min.css.map */

.slick-dots,.slick-next,.slick-prev{position:absolute;display:block;padding:0}.slick-dots li button:before,.slick-next:before,.slick-prev:before{font-family:slick;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}.slick-loading .slick-next,.slick-prev{font-size:0;line-height:0;top:50%;width:20px;height:20px;transform:translate(0,-50%);cursor:pointer;color:transparent;border:none;outline:0;background:0 0}.slick-next:focus,.slick-next:hover,.slick-prev:focus,.slick-prev:hover{color:transparent;outline:0;background:0 0}.slick-next:focus:before,.slick-next:hover:before,.slick-prev:focus:before,.slick-prev:hover:before{opacity:1}.slick-next.slick-disabled:before,.slick-prev.slick-disabled:before{opacity:.25}.slick-next:before,.slick-prev:before{font-size:20px;line-height:1;opacity:.75;color:#fff}.slick-prev{left:-25px}[dir=rtl] .slick-prev{right:-25px;left:auto}.slick-prev:before{content:'←'}.slick-next:before,[dir=rtl] .slick-prev:before{content:'→'}.slick-next{right:-25px}[dir=rtl] .slick-next{right:auto;left:-25px}[dir=rtl] .slick-next:before{content:'←'}.slick-dotted.slick-slider{margin-bottom:30px}.slick-dots{bottom:-25px;width:100%;margin:0;list-style:none;text-align:center}.slick-dots li{position:relative;display:inline-block;width:20px;height:20px;margin:0 5px;padding:0;cursor:pointer}.slick-dots li button{font-size:0;line-height:0;display:block;width:20px;height:20px;padding:5px;cursor:pointer;color:transparent;border:0;outline:0;background:0 0}.slick-dots li button:focus,.slick-dots li button:hover{outline:0}.slick-dots li button:focus:before,.slick-dots li button:hover:before{opacity:1}.slick-dots li button:before{font-size:6px;line-height:20px;position:absolute;top:0;left:0;width:20px;height:20px;content:'•';text-align:center;opacity:.25;color:#000}.slick-dots li.slick-active button:before{opacity:.75;color:#000}/*# sourceMappingURL=slick-theme.min.css.map */
#create-goal-widget-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 35px;
}

#create-goal-widget-wrapper > div {
    
}

#create-goal-widget-wrapper > div:last-child {
    margin-left: 18px;
}

#create-goal-widget-wrapper > div label {
    display: block;
    color: #3f526d;
    font-size: 12px;
    margin-bottom: 10px;
    margin-top: -20px;
}

#create-goal-widget-wrapper > div label + .MuiTextField-root {
    margin: 0;
}

#create-goal-widget-wrapper > div label + .MuiTextField-root input {
    font-size: 12px;
    color: #3f526d;
    padding-top: 0;
    padding-bottom: 0;
    height: 38px;
    border: 0;
    border-radius: 6px;
    padding-right: 9px;
    width: 122px;
}

#create-goal-widget-wrapper > div label + .MuiTextField-root fieldset {
    border: 0;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    transition: 300ms ease;
}

#create-goal-widget-wrapper > div label + .MuiTextField-root input:hover ~ fieldset {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
}

#create-goal-widget-wrapper > div label + .MuiTextField-root input:focus ~ fieldset {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
}
.add-question-box-wrapper{
    padding:11px;
    width:100%;
}

.add-question-box-title{
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
    white-space: nowrap;
}

.add-question-box-filters{
    display:flex;
    align-items:center;
    grid-gap:10px;
    gap:10px;
    margin-bottom:17px;
}

.add-question-select-box{
    width:200px;
    font-size: 14px!important;
    font-weight: 600!important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
}

._add-question-select-box{
    font-size: 14px!important;
    font-weight: 600!important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
}

.add-question-select-box fieldset{
    border:none;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius:8px!important;
}

.add-question-select-box .MuiSelect-select{
    border-radius:8px!important;
    padding:15px 20px;
    /*padding-top: 10px;*/
    /*padding-bottom: 10px;*/
}

.add-question-select-box .MuiInputLabel-outlined{
    transform: translate(15px, 15px) scale(1)!important;
}

.add-question-select-box .MuiInputLabel-outlined.MuiFormLabel-filled{
    display:none;
}

.add-question-box-title-input,
.add-question-box-question-input{
    padding: 10px 20px!important;
    border-radius: 6px;
    background-color: #f9f8fd;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color:#3f526d;
    width:100%;
}

.add-question-box-question-input{
    margin-bottom:17px;
}

.add-question-box-footer{
    display:flex;
    justify-content:space-between;
}

.add-question-box-footer .add-question-box-buttons{
    display:flex;
    grid-gap:14px;
    gap:14px;
}

.add-question-box-footer .cancel-button,
.add-question-box-footer .add-button{
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    text-transform:capitalize!important;
    box-shadow:none;
    width:84px;
    height:30px;
    border-radius:4px!important;
}

.add-question-box-footer .cancel-button{
    background-color: #f6f6f6;
    color:#3f526d;
}

.add-question-box-wrapper .target-group-selectbox {
    background-color: rgba(0,0,0,0.05)!important;
    height:38px;
    overflow:hidden;
}

#contacts-widget {
  width: 290px;
}

#contacts-widget .contact-item{
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: flex-start;
    position: relative;
  }
  
  #contacts-widget .contact-item:not(:last-child) {
    margin-bottom: 12px;
  }
  
  #contacts-widget .contact-item .contact-avatar {
    width: 54px;
    height: 54px;
    padding: 3px;
    background-color: #f9f8fd;
    border-width: 2px;
    border-style: solid;
    margin-right: 14px;
}
  
  #contacts-widget .contact-item .contact-title{
    font-size: 18px;
    font-weight: 600;
    color: #3f526d;
}
#events-widget {
  width: 290px;
}

#events-widget .event-item{
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-bottom: 18px;
    position: relative;
  }
  
  #events-widget .event-item .event-avatar {
    margin-right: 19px;
    display: block;
  }
  
  #events-widget .event-item .event-title{
    font-size: 14px;
    font-weight: 600;
    display: block;
    margin-right: 30px;
    color: #3f526d;
  }
  
  #events-widget .event-item .event-close-button{
    position: absolute;
    right:0;
    top:0;
  }
  
  #events-widget .event-item .event-close-button svg.MuiSvgIcon-root{
    font-size: 16px;
    color: #e7e7e7;
  }
  
  #events-widget .empty-events-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    height: 287px;
  }
  
  #events-widget .empty-events-wrapper img {
    
  }
  
  #events-widget .empty-events-wrapper span {
    flex: 1 0 100%;
    max-width: 100%;
    margin-top: 40px;
    text-align: center;
    font-size: 14px;
    color: rgba(63, 82, 109, 0.5);
    font-weight: 600;
  }
#notifications-widget {
  width: 290px;
}

#notifications-widget .notifications-clear-all-button{
    text-decoration: underline;
    text-transform: none;
    font-size: 12px;
  }
  
  #notifications-widget .notification-item{
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-bottom: 18px;
    position: relative;
  }
  
  #notifications-widget .notification-item .notification-avatar {
    width: 48px;
    height: 48px;
    padding: 3px;
    background-color: #f9f8fd;
    border-width: 2px;
    border-style: solid;
    margin-right: 15px;
  }
  
  #notifications-widget .notification-item .texts{
    margin-right: 30px;
  }
  
  #notifications-widget .notification-item .texts .notification-text{
    font-size: 14px;
    font-weight: 600;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    color: #3f526d;
    letter-spacing: 0;
  }
  
  #notifications-widget .notification-item .texts .notification-created_at{
    font-size: 12px;
    opacity: 0.8;
    color: #3f526d;
  }
  
  #notifications-widget .notification-item .notification-close-button{
    position: absolute;
    right:0;
    top:0;
  }
  
  #notifications-widget .notification-item .notification-close-button svg.MuiSvgIcon-root{
    font-size: 16px;
    color: #e7e7e7;
  }
  
  #notifications-widget .view-all-button {
    text-transform: none;
    font-size: 16px;
    margin-top: 2px;
  }
  
  #notifications-widget .empty-notification-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    height: 246px;
  }
  
  #notifications-widget .empty-notification-wrapper img {
      
  }
  
  #notifications-widget .empty-notification-wrapper span {
    flex: 1 0 100%;
    max-width: 100%;
    margin-top: 40px;
    text-align: center;
    font-size: 14px;
    color: rgba(63, 82, 109, 0.5);
    font-weight: 600;
  }
#calendar-widget .sdp{
    width:unset;
    font-family:Open Sans,sans-serif;
    box-shadow:none;
    border:none;
    padding-right:0;
    padding-left:0;
}

#calendar-widget .sdp--header{
    border-bottom:1px solid rgba(63, 82, 109, 0.12);
    border-top:1px solid rgba(63, 82, 109, 0.12);
    padding:10px 0;
}

#calendar-widget.generic-card .card-divider{
    display:none;
}

#calendar-widget .sdp--header .sdp--square-btn{
    border:none;
    box-shadow:none;
    color:#3f526d;
    opacity:0.3;
}

#calendar-widget .sdp--header__main{
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #3f526d;
}

#calendar-widget .sdp--grid>p.sdp--text{
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #7a879b;
}

#calendar-widget .sdp--grid{
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
}

#calendar-widget .sdp--grid:last-child .sdp--text {
    color: #3f526d;
    opacity:0.7;
}

#calendar-widget .sdp--grid:last-child .sdp--text__inactive {
    color: #3f526d;
    opacity:0.3;
}

#calendar-widget .sdp--grid:last-child .sdp--date-btn__selected{
    background-color:#7f6cfc;
    opacity:1;
    color:white;
}

#news-widget {
    width: 290px;
}

/*#news-widget .news-item{*/
/*    display: flex;*/
/*    align-items: center;*/
/*    flex-wrap: nowrap;*/
/*    justify-content: flex-start;*/
/*    margin-bottom: 18px;*/
/*    position: relative;*/
/*}*/

#news-widget .news-item{
    margin-bottom:11px;
}

#news-widget .news-item .news-item-header{
    display:flex;
    justify-content: space-between;
    align-items:center;
    margin-bottom:9px;
}

#news-widget .news-item .news-title{
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
}

#news-widget .news-item .news-content{
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #3f526d;
}

#news-widget .news-item .news-item-header .news-created_at{
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: rgba(63, 82, 109, 0.5);
}

#news-widget .news-item .news-divider{
    height: 1px;
    background: rgba(63, 82, 109, 0.12);
    width:80%;
    margin:10px 0;
}

#news-widget .view-all-button {
    text-transform: none;
    font-size: 16px;
    margin-top: 22px;
    height:44px;
}

#news-widget .empty-news-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    height: 246px;
}

#news-widget .empty-news-wrapper span {
    flex: 1 0 100%;
    max-width: 100%;
    margin-top: 40px;
    text-align: center;
    font-size: 14px;
    color: rgba(63, 82, 109, 0.5);
    font-weight: 600;
}

#profile-widget{
  padding-left: 27px;
  padding-right: 24px;
}

#profile-widget .profile-widget-item:not(.profile-summary-view-wrapper){
  margin-top: 40px;
}

#profile-widget .about-me .section-title {
  margin-bottom: 8px;
  font-size: 16px;
  color: #3f526d;
  font-weight: 600;
}

#profile-widget .about-me .section-text {
  line-height: 22px;
  font-size: 12px;
  color: #3f526d;
  letter-spacing: 0;
}

/* @OTHER USERS */
#profile-widget .other-users .section-title {
  margin-bottom: 20px;
  font-size: 16px;
  color: #3f526d;
  font-weight: 600;
}

#profile-widget .other-users .users-wrapper {
  margin:0 -6px;
}

#profile-widget .other-users .user-item {
  width: 58px;
  flex: 0 0 58px;
  max-width: 58px;
  padding: 4px;
}

#profile-widget .other-users .user-item .user-avatar{
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

#profile-widget .other-users .view-all-button {
  text-transform: none;
  margin-top: 30px;
  font-size: 16px;
}
#other-members-widget{
    padding-left: 27px;
    padding-right: 24px;
}

/* @OTHER USERS */
#other-members-widget .other-users .section-title {
    margin-bottom: 20px;
    font-size: 16px;
    color: #3f526d;
    font-weight: 600;
}

#other-members-widget .other-users .users-wrapper {
    margin:0 -6px;
}

#other-members-widget .other-users .user-item {
    width: 58px;
    flex: 0 0 58px;
    max-width: 58px;
    padding: 4px;
}

#other-members-widget .other-users .user-item .user-avatar{
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

#other-members-widget .other-users .view-all-button {
    text-transform: none;
    margin-top: 30px;
    font-size: 16px;
}

#other-members-widget .empty-members-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    height: 246px;
}

#other-members-widget .empty-members-wrapper span {
    flex: 1 0 100%;
    max-width: 100%;
    margin-top: 40px;
    text-align: center;
    font-size: 14px;
    color: rgba(63, 82, 109, 0.5);
    font-weight: 600;
}

#chat-widget {
    width: 290px;
}

#chat-widget .chat-item{
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: flex-start;
    position: relative;
    padding:0!important;
    width:100%;
}

#chat-widget .chat-item:not(:last-child) {
    margin-bottom: 16px;
}

#chat-widget .chat-item .chat-avatar {
    width: 54px;
    height: 54px;
    padding: 3px;
    margin-right: 17px;
}

#chat-widget .chat-item .chat-title{
    text-transform: none!important;
    font-size: 18px!important;
    font-weight: 600!important;
    color: #3f526d;
}

#chat-widget .view-all-button {
    text-transform: none;
    font-size: 16px;
    margin-top:4px;
    margin-bottom:10px;
    height:44px;
}

.landing-page-main-container .tab-menu.box-view{
    display:flex;
    align-items:center;
    grid-gap:30px;
    gap:30px;
    margin-bottom: 7px;
}

.landing-page-main-container .tab-menu .MuiButtonBase-root {
    font-weight: normal;
    padding-left: 17px;
    padding-right: 17px;
}

.landing-page-main-container .tab-menu .MuiButtonBase-root[aria-selected="true"] {
    font-weight: 600;
    /* color: inherit ; */
}

.filter-button{
    min-width:50px;
    height:50px;
    margin-bottom:24px;
    border-radius: 8px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
}

.filter-button img{
    width:24px;
    height:24px;
}

.filter-button button{
    min-width:unset;
    height:100%;
    width:100%;
    border-radius:8px;
    padding:0;
}

.filter-menu .MuiPopover-paper{
    border-radius: 8px!important;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);
    padding:16px 20px!important;
}

.filter-container{
    width:220px;
}

.filter-container .MuiMenu-list{
    padding:0;
}

.filter-menu-header{
    display:flex;
    justify-content: space-between;
    align-items:center;
    border-bottom:solid 0.5px #3f526d;
    margin-bottom:14px;
}

.filter-menu-header h3, .filter-menu-header span{
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;;
}

.filter-menu-header h3{
    font-size: 14px;
    font-weight: 600;
}

.filter-menu-header span{
    font-size:12px;
    text-decoration:underline;
    cursor:pointer;
}

.filter-select-box,
.filter-multiple-select-box{
    width:100%;
    margin-top:11px!important;
    margin-bottom:24px!important;
}

.filter-container label,
.filter-multiple-select-search{
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
}

.filter-select-box label{
    color:rgba(0, 0, 0, 0.3)!important;
}

.filter-multiple-select-box .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
    padding:2px 9px;
}

.filter-menu-buttons{
    display:flex;
    align-items:center;
    grid-gap:10px;
    gap:10px;
}

.filter-container .filter-button{
    font-size: 14px;
    font-weight: 600!important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal!important;
    letter-spacing: normal;
    text-align: center;
    text-transform:capitalize!important;
    width:100px;
    height:36px;
    padding:9px 30px!important;
    border-radius: 6px;
}

.filter-container .filter-button:last-child{
    border-width: 0.5px;
    border-style: solid;
    color: #3f526d;
}

.select-box-chips{
    display:flex;
    grid-gap:2px;
    gap:2px;
}

.MuiChip-root{
    border-radius: 4px!important;
    background-color: rgb(127, 108, 252,0.1)!important;
    font-size: 14px!important;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #253873!important;
    /*margin:0 5px 5px 0!important;*/
}

.filter-container .filter-select-box .MuiSelect-select{
    padding-top: 10px;
    padding-bottom: 10px;
}

.filter-select-box .MuiInputLabel-outlined{
    transform: translate(14px, 12px) scale(1)!important;
}

.filter-select-box .MuiInputLabel-outlined.MuiFormLabel-filled{
    display:none;
}

.filter-multiple-select-search .MuiInputBase-input::-moz-placeholder{
    color: #3f526d!important;
}

.filter-multiple-select-search .MuiInputBase-input:-ms-input-placeholder{
    color: #3f526d!important;
}

.filter-multiple-select-search .MuiInputBase-input::placeholder{
    color: #3f526d!important;
}

.back-button{
    font-family: OpenSans,sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-transform:capitalize!important;
    margin-bottom:24px!important;
    padding:14px 20px!important;
    height:50px;
    border-radius:8px!important;
    box-shadow:none!important;
    margin-right:20px!important;
}

.back-button img{
    width:18px;
    height:18px;
}

.tab-menu.answer-view{
    grid-gap:0;
    gap:0;
}

.tab-menu.answer-view .filter-button{
    margin-left:10px!important;
}

.student-filter-select-boxes{
    display:flex;
    align-items:center;
    grid-gap:14px;
    gap:14px;
}

.student-filter-select-box{
    width:200px;
    position: relative;
}

.student-filter-select-box .progress-icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: wait;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.student-filter-select-box fieldset{
    border:none;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius:8px!important;
}

.student-filter-select-box .MuiSelect-select{
    border-radius:8px!important;
    padding:15px 20px;
    /*padding-top: 10px;*/
    /*padding-bottom: 10px;*/
}

.student-filter-select-boxes .MuiInputLabel-outlined{
    transform: translate(15px, 18px) scale(1)!important;
}

.student-filter-select-boxes .MuiInputLabel-outlined.MuiFormLabel-filled{
    display:none;
}

/*.filter-select-box-search{*/
/*    outline:none;*/
/*    border:none;*/
/*    padding:10px 20px;*/
/*    width:180px;*/
/*    font-size:14px;*/
/*    font-family: Open Sans,sans-serif;*/
/*    font-weight: 600;*/
/*    font-stretch: normal;*/
/*    font-style: normal;*/
/*    line-height: normal;*/
/*    letter-spacing: normal;*/
/*    color: rgba(63, 82, 109,0.5)!important;*/
/*    border-bottom:1px solid rgba(63, 82, 109,0.5)*/
/*}*/

.filter-select-box legend,.MuiAutocomplete-root label,.MuiInputLabel-outlined label{
    /*display:none!important;*/
}

.student-search-form{
    margin-bottom:20px!important;
    width:100%;
}

.student-search-input{
    border-radius:6px!important;
    /*opacity: 0.5;*/
    font-family: Open Sans,sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(63, 82, 109,0.5)!important;
}

.student-search-input input{
    padding:18px 20px!important;
}

.student-search-input img{
    width:20px;
    height:20px;
    margin-right:5px;
}

.student-list-container{
    display:flex;
    flex-wrap:nowrap
}

.student-list-container .user-list-wrapper{
    display:flex;
    flex-wrap:wrap;
    margin:-10px;
    width:calc(100% + 20px);
}

.student-list-container .user-list-item{
    flex:0 0 25%;
    max-width:25%;
    box-sizing:border-box;
    padding:10px;
}

@media(max-width:1400px){
    .student-list-container .user-list-item{
        flex:0 0 33.33%;
        max-width:33%;
    }
}

@media(max-width:960px){
    .student-list-container .user-list-item{
        flex:0 0 50%;
        max-width:50%;
    }
}

.student-list-filters-container{
    display:flex;
    align-items:center;
    justify-content: space-between;
    margin-bottom:30px;
}

.student-list-filters{
    display:flex;
    align-items:center;
}

.student-list-filters .page-title{
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
    margin-right:78px;
}

.my-profile-button{
    padding: 14px 48px!important;
    border-radius: 8px!important;
    text-transform:capitalize!important;
    font-size:16px!important;
    font-weight:600!important;
}

.widget-menu .MuiPopover-paper {
    padding:20px;
    width:220px;
    border-radius:8px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.24);
}

.widget-menu-buttons{
    display:flex;
    justify-content:space-between;
    margin-top:16px;
}

.widget-menu-form span{
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: normal;
}

.widget-menu-buttons .widget-button{
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    text-transform:capitalize!important;
    box-shadow:none;
    width:84px;
    height:30px;
    border-radius:4px;
}

.widget-menu-buttons .widget-button:last-child{
    background-color: #f6f6f6;
    color:#3f526d;
}

.landing-page-main-container .widget-button {
    text-transform: unset;
    width: 120px;
    height: 44px;
}
.landing-page-main-container .pdf-button {
    width: 44px;
    height: 44px;
    min-width: unset;
    margin-left: 20px;
}

#landing-page-container {
    flex-flow: row nowrap;
}

#landing-page-container > .left-side,
#landing-page-container > .right-side {
    flex: 0 0 290px;
    max-width: 290px;
}

#page-header .right-side {
    display:flex;
}

#landing-page-container .content-side {
    flex: 1 1 auto;
    max-width: 100%;
    padding-left: 32px;
    padding-right: 32px;
    min-width: 0;
}

#landing-page-container .content-rightSide-fullWidth {
    padding-right: 0;
}

#landing-page-container .content-leftSide-fullWidth{
    padding-left: 0;
}

