.single-dot{
    width: 2px;
    height: 2px;
    margin: 0 12px;
    background-color: #3f526d;
}

.single-post{
    margin-bottom:24px;
}

.boxed-view-container{
    max-width:33.33333333%;
    flex-basis:33.33333333%;
    flex-grow:0;
    box-sizing:border-box;
    padding:11px;
    margin-bottom:0;
}

.list-view-container{
    max-width:100%;
    flex-basis:100%;
    flex-grow:0;
    box-sizing:border-box;
    padding:11px;
    margin-bottom:0!important;
}

.badges-container{
    max-width:50%;
    flex-basis:50%;
    flex-grow:0;
    box-sizing:border-box;
    padding:11px;
    margin-bottom:0;
}

@media (max-width:1400px){
    .boxed-view-container{
        max-width:50%;
        flex-basis:50%;
    }
}

.boxed-view{
    /*min-width:270px;*/
}

.post-image{
    width: 34px;
    height: 34px;
    border-radius:50%;
    object-fit: contain;
    margin-right:10px;
}

.single-post-container{
    padding: 19px 30px 20px 30px;
}

.boxed-view .single-post-container{
    padding:20px 16px 14px 14px;
}

.single-post-container h4{
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    color: #3f526d;
    margin:0;
}

.single-post-container span{
    color: #3f526d;
    font-size: 12px;
    opacity: 0.68;
}

.single-post-wrapper{
    position:relative;
}

.more-vertical{
    color:#3f526d;
    opacity:0.24;
    position:absolute!important;
    top:5px;
    right:0;
}

.post-description{
    margin-top:17px;
}

.post-description p{
    font-size: 16px;
    line-height: 1.63;
    margin-bottom:20px;
    color:#3f526d;
}

.boxed-view .post-description p.box-view-description{
    min-height:90px;
}

.boxed-view .post-description p.box-view-description,
.list-view .post-description p.box-view-description{
    font-size:14px;
    line-height:1.57;
    margin-top:20px;
    margin-bottom:18px;
}

.post-description p.goal-text{
    font-weight:600;
}

.post-description p.video-text{
    margin-bottom:0;
}

.single-post-container hr{
    border-color:rgba(63, 82, 109, 0.12);
    border-width:1px;
}

.file-box{
    margin-bottom:20px!important;
}

.download-wrapper{
    text-decoration:none;
}

.file-box span{
    font-size: 18px;
    font-weight: 600;
    color: #3f526d;
    margin-left:16px;
    opacity:1;
}

.boxed-view .file-box span,
.list-view .file-box span{
    margin-left:10px;
}

.file-box .file-icon{
    width:38px;
    height:38px;
}

.boxed-view .file-box .file-icon,
.list-view .file-box .file-icon{
    width:20px;
    height:20px;
}

.file-box .download-icon{
    width:18px;
    height:18px;
}

.boxed-view .file-box .download-icon,
.list-view .file-box .download-icon{
    width:12px;
    height:12px;
    margin-right:8px;
}

.post-pictures{
    margin-bottom:20px!important;
    display:flex;
    align-items:stretch;
    flex-wrap:wrap;
    gap:12px;
}

.post-pictures .first-half, .post-pictures .second-half{
    max-height:256px;
    flex:1;
    width:100%;
    border-radius:8px;
    object-fit:cover
}

.post-pictures .half-height{
    max-height:122px;
    width:100%;
    flex:1;
    border-radius:8px;
    object-fit:cover
}

.post-pictures-second-half{
    display:flex;
    flex-direction: column;
    flex:1;
    gap:12px;
}

.post-pictures .full-size{
    width:100%;
    max-height:256px;
}

.post-link{
    font-size: 16px!important;
    color: #3a60d7!important;
    margin-bottom:11px!important;
}

.goal-picture,.video-picture{
    width:100%;
}

.video-picture-container{
    position:relative;
    cursor:pointer;
    margin-bottom:20px;
    width:100%;
    aspect-ratio:16/9;
}

.video-picture{
    border-radius:8px;
    height:100%;
}

.video-icon{
    position:absolute;
    margin:auto;
    left:0;
    right:0;
    top:0;
    bottom:0;
    width:50px;
    height:50px;
}

.single-post-container .due-date{
    border-radius: 6px;
    background-color: #f9f8fd;
    padding:8px 15px 7px 15px;
    margin-bottom:22px;
    display:inline-block;
}

.single-post-container .due-date span{
    font-size: 12px;
    text-align: center;
    color: #3f526d;
}

.single-post-container .due-date span:first-child{
    font-weight:600;
}

.menu-list img{
    margin-right:22px;
    width:20px;
}

.menu-list li{
    font-size: 16px;
    color:#3f526d;
}

.boxed-view{
    /*max-width:33.33%;*/
    /*flex:0 0 33%;*/
    width:100%;
}

.boxed-view span.file-text,
.list-view span.file-text{
    font-size:14px;
}

.badges-container .goal-picture{
    max-height:160px;
    object-fit:contain;
    margin-bottom:30px;
    margin-top:20px;
}

.questions-container p:first-child{
    font-size:14px;
}

.questions-container .answer-text{
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(63, 82, 109, 0.6);
}

.questions-container .answer-text:hover{
    text-decoration:underline;
    cursor:pointer;
}

.single-post-container .comments{
    display:flex;
    flex-direction:column;
    margin-top:20px;
}

.single-post-container .load-more-button{
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
    text-transform:unset!important;
    margin-top:20px;
    max-width:200px;
    margin-left:20px;
}

.single-post-container .load-more-button .MuiButton-label{
    opacity:1!important;
}

.single-post-container .load-more-button img{
    width:8px;
    height:16px;
}

.replies .comment-container{
    background-color:#fff;
}

.create-content-dialog-wrapper .dialog-content .image-list {
    border-width: 0.5px;
    border-style: solid;
    margin-top: 40px;
    border-radius: 8px;
    position: relative;
    background-color: #f9f8fd;
    padding: 7px;
}

.single-post-container .image-list .image-item {
    padding: 6px;
    position: relative;
}

.single-post-container .image-list.__one-item .image-item {
    width: 100%;
    height: 256px;
}

.single-post-container .image-list.__one-item .image-item img{
    object-fit: contain!important;
}

.single-post-container .image-list.__two-items {
    display: grid;
    grid-template-columns: 50% 50%;
}

.single-post-container .image-list.__two-items .image-item {
    width: 100%;
    height: 256px;
}


.single-post-container .image-list.__three-items {
    display: grid;
    grid-template-columns: 50% 50%;
}


.single-post-container .image-list.__three-items .image-item:nth-child(1) {
    grid-row: span 2;
    height: 256px;
}

.single-post-container .image-list.__three-items .image-item:nth-child(2) {
    grid-row: span 1;
    height: 128px;
}

.single-post-container .image-list.__three-items .image-item:nth-child(3) {
    grid-row: span 1;
    height: 128px;
}

.single-post-container .images-count span {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    text-align: center;
    font-size: 20px;
    letter-spacing: 0;
    position: absolute;
    left:0;
    top:0;
    background-color: rgba(0,0,0,0.35);
    width: 100%;
    width: calc(100% - 12px);
    height: 100%;
    height: calc(100% - 12px);
    margin-left: 6px;
    margin-top: 6px;
    user-select: none;
    pointer-events: none;
    border-radius: 8px;
    overflow: hidden;
    color: #fff;
}

.single-post-container .image-item img {
    width: 100%;
    height: 100%;
    cursor: pointer;
    object-fit: cover;
    border-radius: 8px;
}
