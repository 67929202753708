#image-carousel-dialog .MuiDialog-paper{
    background-color: transparent;
    max-width: 80vw;
    box-shadow: none;
    width: 100%;
    position: relative;
    overflow-y: visible;
}

#image-carousel-dialog .dialog-container{
    padding-top: 0;
    overflow-y: visible;
}

#image-carousel-dialog .remove-image-button {
    position: absolute;
    right:140px;
    top:0px;
    transform: translateY(-100%);
}

#image-carousel-dialog .remove-image-button svg {
    font-size: 35px;
    color: #fff;
}

#image-carousel-dialog .close-dialog-button {
    position: absolute;
    right: 90px;
    top:0;
    transform: translateY(-100%);
}

#image-carousel-dialog .close-dialog-button svg {
    font-size: 35px;
    color: #fff;
}

#image-carousel-dialog .carousel-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
}

#image-carousel-dialog .back-button svg,
#image-carousel-dialog .next-button svg {
    font-size: 44px;
}

#image-carousel-dialog .back-button:not(.Mui-disabled) svg,
#image-carousel-dialog .next-button:not(.Mui-disabled) svg {
    color: #fff;
    font-size: 44px;
}

#image-carousel-dialog .next-button svg {
    transform: rotate(180deg);
}

#image-carousel-dialog .images-wrapper {
    margin-left: 40px;
    margin-right: 40px;
    flex: 1 1 auto;
    max-width: 100%;
}

#image-carousel-dialog .images-wrapper .image-wrapper {
    width: 100%;
    height: 60vh;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}
