
.custom-pagination .MuiPagination-ul li:not(:last-child) {
  margin-right: 12px;
}

.custom-pagination .MuiPagination-ul li .page-button {
  width: 44px;
  height: 44px;
  font-size: 16px;
  min-width: unset;
}

.custom-pagination .MuiPagination-ul li .previousButton,
.custom-pagination .MuiPagination-ul li .nextButton {
  height: 44px;
  width: 124px;
  font-size: 16px;
  text-transform: unset;
}

.custom-pagination .MuiPagination-ul li .previousButton svg,
.custom-pagination .MuiPagination-ul li .nextButton svg {
  font-size: 24px;
}

.custom-pagination .MuiPagination-ul li .nextButton svg {
  transform: rotate(180deg);
}

.custom-pagination .MuiPagination-ul li button:disabled {
  background-color: #fff;
  border: 1px solid #3a60d7;
  opacity: 0.4;
  color: #3f526d;
}