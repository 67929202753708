#target-group-wrapper .target-group-selectbox {
    color: #3f526d;
    border-radius: 6px!important;
    background-color: #f9f8fd;
}
  
#target-group-wrapper .target-group-selectbox > .MuiSelect-select{
    padding-top: 13px;
    padding-bottom: 12px;
}
  
#target-group-wrapper .target-group-selectbox.__small > .MuiSelect-select {
    padding-top: 10px;
    padding-bottom: 9px;
}
  
#target-group-wrapper .target-group-selectbox .selectbox-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    color: #3f526d;
    font-size: 14px;
    font-weight: 600;
}
  
#target-group-wrapper .target-group-selectbox .selectbox-wrapper .selectbox-icon {
    margin-right: 10px;
    font-size: 14px;
    color: #3f526d;
    font-size: 16px;
}

.target-group-menu-item .menu-item-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: row nowrap;
} 


.target-group-menu-item .menu-item-wrapper .menu-icon {
    opacity: 0.5;
    margin-right: 8px;
} 

.target-group-menu-item .menu-item-wrapper .texts b {
    display: block;
    font-size: 14px;
}

.target-group-menu-item .menu-item-wrapper .texts small {
    font-size: 12px;
    opacity: 0.5;
}