.comments-dialog-body{
    display:flex;
    flex-direction:column;
    margin:1px 6px;
    min-width:300px;
    padding:8px 24px
}

.comments-dialog-title{
    font-size: 18px;
    font-weight: 600;
    color:#3f526d;
    margin:0 0 30px 0;
}

.comment-container{
    display:flex;
    background-color:#f9f8fd;
    border-radius:10px;
    margin-left:18px;
    padding:8px 10px;
}

.comment-container p{
    font-size: 10px;
    line-height: normal;
    color: #3f526d;
    margin:0;
}

.comment-text-container{
    /*margin-left:18px;*/
}

.comments-dialog-create-box-container{
    padding:0 34px 27px 30px;
}

.comment-box-divider{
    margin:0;
    height:1px;
    background-color:rgba(0, 0, 0, 0.46);
    width:100%;
    opacity:0.1;
}
