
.add-to-dialog-wrapper {
    width: 529px;
    max-width: 100%;
}

.add-to-dialog-wrapper[data-view="GALLERY"],
.add-to-dialog-wrapper[data-view="CAMERA"] {
    width: 900px;
}

.add-to-dialog-wrapper .dialog-title {
    text-align: center;
    margin-top: -6px;
}

.add-to-dialog-wrapper .dialog-title h6 {
    color: #3f526d;
    font-size: 18px;
    font-weight: bold;
}

.add-to-dialog-wrapper .dialog-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    min-height: 240px;
    padding: 20px 50px;
}

.add-to-dialog-wrapper .dialog-content .add-to-dialog-option-button {
    text-transform: unset;
    width: 170px;
    max-width: 170px;
}

.add-to-dialog-wrapper .dialog-content .add-to-dialog-option-button > .MuiButton-label{
    flex-wrap: wrap;
}

.add-to-dialog-wrapper .dialog-content .add-to-dialog-option-button > .MuiButton-label .MuiButton-startIcon {
    margin-left:0;
    margin-right: 0;
}

.add-to-dialog-wrapper .dialog-content .add-to-dialog-option-button .button-text {
    flex: 1 1 100%;
    max-width: 100%;
    margin-top: 16px;
    color: rgba(63, 82, 109, 0.6);
    line-height: 30px;
    font-weight: 600;
}