
/* Textarea */

#create-content-widget {
    margin-bottom: 24px;
}

#create-content-widget .widget-title{
    font-size: 14px;
    font-weight: 600;
    color: #3f526d;
    margin-bottom: 14px;
    }

#create-content-widget .widget-content .textarea {
}

#create-content-widget .widget-content .textarea textarea {
    font-size: 14px;
    font-weight: 600;
    color: #3f526d;
}

#create-content-widget .widget-content .textarea textarea::placeholder {
    font-size: 14px;
    font-weight: 600;
    color: rgba(63, 82, 109, 0.68);
}

#create-content-widget .widget-content .textarea .mui-textarea-wrapper{
    padding: 22px 23px;
    min-height: 86px;
    border-radius: 8px;
    margin-bottom: 40px;
    background-color: #f9f8fd;
}

#create-content-widget .widget-content#add-to-badge .textarea .mui-textarea-wrapper{
    min-height: 59px;
    margin-bottom: 30px;
}


#create-content-widget .widget-content .textarea .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
    margin-top: 0;
}

#create-content-widget .widget-content .textarea .avatar {
    width: 42px;
    height: 42px;
    margin-right: 15px;
}


/* CREATE CONTENT DIALOG */
.create-content-dialog-wrapper {
    width: 640px;
    padding: 20px 26px;
    padding-top: 0;
    max-width: 100%;
}

.create-content-dialog-wrapper .dialog-title {
    font-size: 18px;
    color: #3f526d;
    font-weight: 600;
}

.create-content-dialog-wrapper .dialog-content {
}

.create-content-dialog-wrapper .dialog-content .personal-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 30px;
    margin-bottom: 26px;
}

.create-content-dialog-wrapper .dialog-content .personal-info .avatar {
    margin-right: 14px;
}

.create-content-dialog-wrapper .dialog-content .personal-info strong {
    font-size: 16px;
    font-weight: 600;
    color: #3f526d;
}

.create-content-dialog-wrapper .dialog-content .textarea textarea {
    font-size: 16px;
    color: #3f526d;
}

.create-content-dialog-wrapper .dialog-content .textarea textarea::placeholder {
    color: rgba(63, 82, 109, 0.5);
}

.create-content-dialog-wrapper .dialog-content .image-list {
    border-width: 0.5px;
    border-style: solid;
    margin-top: 40px;
    border-radius: 8px;
    position: relative;
    background-color: #f9f8fd;
    padding: 7px;
}

.create-content-dialog-wrapper .dialog-content .image-list .image-item {
    padding: 6px;
    position: relative;
}

.create-content-dialog-wrapper .dialog-content .image-list.__one-item .image-item {
    width: 100%;
    height: 256px;
}

.create-content-dialog-wrapper .dialog-content .image-list.__one-item .image-item img{
    object-fit: contain!important;
}

.create-content-dialog-wrapper .dialog-content .image-list.__two-items {
    display: grid;
    grid-template-columns: 50% 50%;
}

.create-content-dialog-wrapper .dialog-content .image-list.__two-items .image-item {
    width: 100%;
    height: 256px;
}


.create-content-dialog-wrapper .dialog-content .image-list.__three-items {
    display: grid;
    grid-template-columns: 50% 50%;
}


.create-content-dialog-wrapper .dialog-content .image-list.__three-items .image-item:nth-child(1) {
    grid-row: span 2;
    height: 256px;
}

.create-content-dialog-wrapper .dialog-content .image-list.__three-items .image-item:nth-child(2) {
    grid-row: span 1;
    height: 128px;
}

.create-content-dialog-wrapper .dialog-content .image-list.__three-items .image-item:nth-child(3) {
    grid-row: span 1;
    height: 128px;
}

.create-content-dialog-wrapper .dialog-content .image-list.__higher-than-three-items .images-count {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    text-align: center;
    font-size: 20px;
    letter-spacing: 0;
    position: absolute;
    left:0;
    top:0;
    background-color: rgba(0,0,0,0.35);
    width: 100%;
    width: calc(100% - 12px);
    height: 100%;
    height: calc(100% - 12px);
    margin-left: 6px;
    margin-top: 6px;
    user-select: none;
    pointer-events: none;
    border-radius: 8px;
    overflow: hidden;
    color: #fff;
}

.create-content-dialog-wrapper .dialog-content .image-list .image-item img {
    width: 100%;
    height: 100%;
    cursor: pointer;
    object-fit: cover;
    border-radius: 8px;
}

.create-content-dialog-wrapper .dialog-content .image-list.__two-items .image-item {

}

.create-content-dialog-wrapper .dialog-content .closeButton {
    position: absolute;
    right: 5px;
    top: 5px;
    min-width: unset;
}

.create-content-dialog-wrapper .dialog-content .closeButton svg {
    color: #fff;
}

.create-content-dialog-wrapper #create-content-footer-section-wrapper {
    margin-top: 20px;
}