.add-answer-dialog{
    padding:4px 24px 24px 24px;
}

.add-answer-dialog-header{
    display:flex;
    align-items:center;
}

.add-answer-dialog-header img{
    width:34px;
    height:34px;
    border-radius:50%;
}

.add-answer-dialog-header h3{
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
    margin-left:12px;
}

.add-answer-dialog-body p{
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #3f526d;
    margin:20px 0 14px 0;
}

.add-answer-dialog textarea{
    border:none;
    resize:none;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(63, 82, 109, 0.36);
    width:100%;
}

.add-answer-dialog textarea::placeholder {
    color:rgba(63, 82, 109, 0.36);
}

.add-answer-dialog textarea:focus{
    outline:none;
}

.add-answer-actions{
    display:flex;
    justify-content:flex-end;
    align-items:center;
    gap:8px;
    position:relative;
}

.add-answer-actions img{
    width:20px;
    height:20px;
}

.add-answer-dialog .vertical-line{
    height:22.5px;
    width:1px;
    background-color:rgba(63, 82, 109, 0.12);
}

.add-answer-button{
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    text-transform:capitalize!important;
}
