
#page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 24px;
}

#page-header .left-side {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

#page-header .left-side .page-header-button {
  color: #3f526d;
  font-weight: 600;
  text-decoration: none;
  text-transform: none;
  font-size: 20px;
}

#page-header .left-side .page-header-button .go-back-icon {
  font-size: 22px;
}

#page-header .left-side .page-header-button .page-header-button-text {
  font-weight: 600;
  font-size: 19px;
}