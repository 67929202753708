#create-goal-widget-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 35px;
}

#create-goal-widget-wrapper > div {
    
}

#create-goal-widget-wrapper > div:last-child {
    margin-left: 18px;
}

#create-goal-widget-wrapper > div label {
    display: block;
    color: #3f526d;
    font-size: 12px;
    margin-bottom: 10px;
    margin-top: -20px;
}

#create-goal-widget-wrapper > div label + .MuiTextField-root {
    margin: 0;
}

#create-goal-widget-wrapper > div label + .MuiTextField-root input {
    font-size: 12px;
    color: #3f526d;
    padding-top: 0;
    padding-bottom: 0;
    height: 38px;
    border: 0;
    border-radius: 6px;
    padding-right: 9px;
    width: 122px;
}

#create-goal-widget-wrapper > div label + .MuiTextField-root fieldset {
    border: 0;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    transition: 300ms ease;
}

#create-goal-widget-wrapper > div label + .MuiTextField-root input:hover ~ fieldset {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
}

#create-goal-widget-wrapper > div label + .MuiTextField-root input:focus ~ fieldset {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
}