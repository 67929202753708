#sidebar {
    padding: 26px 0px;
    text-align: center;
    position: relative;
    z-index: 3;
    transition: 300ms ease;
}

#sidebar.__collapsed {
    flex: 0 0 120px;
    max-width: 120px;
    width: 120px;
}

#sidebar:not(.__collapsed) {
    flex: 0 0 265px;
    max-width: 265px;
    width: 265px;
}

#sidebar .sidebar-collapser {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 115px;
    right: -15px;
    background-color: #ffbe00;
    max-width: 30px;
    min-width: unset;
    z-index: 1;
}

#sidebar .sidebar-collapser .chevron-icon {
    transition: 300ms ease transform;
    transition-delay: 300ms;
}

#sidebar:not(.__collapsed) .sidebar-collapser .chevron-icon {
    transform: rotate(180deg);
}

#sidebar .nav-menu-wrapper {
    text-align: center;
}

#sidebar .nav-menu-wrapper .nav-menu-item {
    opacity: 0.5;
    display: block;
    min-width: unset;
    text-transform: unset;
    padding-left: 45px;
    padding-right: 15px;
    width: 100%;
    margin: 0;
    min-height: 40px;
    height: 80px;
    height: -webkit-calc(100vh / 11.5);
    height: calc(100vh / 11.5);
    max-height: 90px;
}

#sidebar:not(.__collapsed) .nav-menu-wrapper .nav-menu-item {
    width: 100%;
}

#sidebar .nav-menu-wrapper .nav-menu-item:hover {
    opacity: 1;
}

#sidebar .nav-menu-wrapper .nav-menu-item:first-child {
    margin-top: 40px;
}

#sidebar .nav-menu-wrapper {}

#sidebar.__collapsed .nav-menu-wrapper .nav-menu-item .button-text {
    opacity: 0;
    transform: translateX(150%);
}

#sidebar .nav-menu-wrapper .nav-menu-item .button-text {
    opacity: 1;
    transform: translateX(0%);
    transition: 300ms ease;
}

#sidebar .nav-menu-wrapper .nav-menu-item .MuiButton-label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
}

#sidebar .nav-menu-wrapper .nav-menu-item .button-text {
    margin-left: 16px;
    color: #fff;
    font-size: 16px;
    pointer-events: none;
}