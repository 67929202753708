.box-controller-container{
    display:flex;
    align-items:center;
    gap:18px;
    margin-bottom:24px;
}

.box-controller-item{
    height:48px;
    width:48px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    border-radius:8px;
    display:flex;
    align-items:center;
    justify-content: center;
    cursor:pointer;
}

.box-controller-item img{
    width:24px;
    height:24px;
}

.box-controller-item:last-child svg{
    font-size: 36px;
}