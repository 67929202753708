.share-to-feed{
    max-width:640px;
    padding:0 26px 21px 26px;
}

.share-to-feed-title{
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
}

.share-to-feed .post-information{
    border-radius:8px;
    border-width: 0.5px;
    border-style: solid;
    background-color: #f9f8fd;
}

.share-to-feed-header{
    display:flex;
    align-items:center;
    margin-top:20px;
}

.share-to-feed-header img{
    width:42px;
    height:42px;
}

.share-to-feed-header h3{
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
    margin-left:14px;
}

.share-to-feed>input{
    padding:20px 16px 20px 0;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
    opacity: 0.5;
    border:none;
    outline:none;
    width:100%;
    margin-bottom:20px;
}

.post-button{
    width:100px;
    height:38px;
    text-transform:capitalize!important;
    padding:10px 35px!important;
    white-space: nowrap;
}

.share-to-feed .target-group-selectbox {
    background-color: rgba(0,0,0,0.05);
    height:38px;
    overflow:hidden;
}

.share-to-feed .single-post-container{
    padding:13px;
}

.post-actions-item,.share-to-feed .post-actions{
    display:flex;
    align-items:center;
}

.post-actions-item{
    gap:14px;
}

.share-to-feed .post-actions{
    justify-content:space-between;
}

.share-to-feed .post-actions{
    margin:30px 13px 0 13px;
}

.post-actions-item:first-child img{
    width:20px;
    height:20px;
}

.post-actions-item:first-child .vertical-line{
    height: 22.5px;
    width: 1px;
    background-color: rgba(63, 82, 109, 0.12);
    margin:0 20px;
}

.post-actions-button,.post-button{
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-transform:capitalize!important;
    color: #3f526d;
}

.post-actions-button .MuiButton-startIcon{
    margin-right:14px;
}


