.top-students-wrapper{
    flex:0 0 250px;
    max-width:250px;
    margin-right:-26px;
    margin-left:40px;
    overflow:hidden;
}
.top-students-menu-container{
    height:100%;
    padding: 30px 5px 40px 40px;
    border-top-left-radius: 40px;
    overflow:hidden;
}

.top-students-menu-container h3,
.top-students-card h4,
.top-students-card span{
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin:0;
}

.top-students-menu-container h3{
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    margin-bottom:36px;
}

.top-students-card{
    display:flex;
    align-items:center;
    gap:15px;
    margin-bottom:28px;
}

.top-students-card img{
    width:46px;
    height:46px;
    border-radius:50%;
    border: solid 2px #fff;
}

.top-students-card h4{
    font-size: 16px;
    font-weight: 600;
    color: #fff;
}

.top-students-card span{
    font-size: 12px;
    font-weight: normal;
    color: rgba(255, 255, 255, 0.76);
}
