.border-colored-text-field-wrapper{
    position:relative;
    display:flex;
    align-items:center;
    width:100%;
}

.border-colored-text-field-container .border-colored-text-field-icon{
    position:absolute;
    right:20px;
}

.student-search-form{
    margin-bottom:20px;
}

.student-search-input{
    border-radius:6px!important;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(63, 82, 109,0.5)!important;
    width:100%;
    padding:15px 55px 15px 15px;
    border-style:solid;
}

.student-search-input input{
    padding:18px 20px!important;
}

.border-colored-text-field-container .border-colored-text-field-icon img.student-search-icon{
    width:20px;
    height:20px;
    margin-right:5px;
}

