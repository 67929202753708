.post-interactions{
    gap:30px;
}

.boxed-view .post-interactions{
    gap:20px;
}

.post-interactions div{
    display:flex;
    align-items:center;
    cursor:pointer;
}

.post-interactions span{
    margin-left:12px;
    font-size:14px;
    color:#3f526d;
}

.boxed-view .post-interactions span:not(.MuiIconButton-label){
    margin-left:8px;
    font-size:12px;
}

.post-interactions img{
    height:18px;
    width:18px;
}

.share-button{
    padding:0!important;
}

.boxed-view .post-interactions svg{
    width:20px;
    height:20px;
}

.share-button:hover{
    background-color:transparent!important;
    text-align:left!important;
}

.share-button .MuiIconButton-label{
    margin:0;
}
