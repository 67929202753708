#root > div {
    max-width: 100%;
}

#main-container {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-flow: row nowrap;
}

#main-container .content-area {
    flex: 1 1 auto;
    max-width: 100%;
    position: relative;
    min-width: 0;
}

#main-container .content-area .radius-background {
    position: absolute;
    left: -1px;
    top: 0;
    width: 40px;
    height: 40px;
    z-index: 1;
}

#main-container .content-area .radius-background-2 {
    position: absolute;
    left: -1px;
    bottom: 0;
    width: 40px;
    height: 40px;
    z-index: 1;
}

#main-container .content-area .content-area-wrapper {
    background-color: #fff;
    padding-left: 26px;
    padding-right: 26px;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    min-height: 100vh;
    min-height: -webkit-calc(100vh - 33px);
    min-height: calc(100vh - 33px);
    z-index: 2;
    position: relative;
}

#main-container.__fullwidth .content-area.content-area-wrapper {
    padding-right: unset;
}