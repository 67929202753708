
#mark-all-notifications-as-read-button {
    text-transform: none;
    font-weight: 600;
    font-size: 16px;
    height: 44px;
}
  
#notifications-page .search-area .search-area-textfield {
    height: 60px;
    width:100%;
    border-radius:8px;
    border-style:solid;
    border-width:1px;
    padding:0 55px 0 20px;
}
  
#notifications-page .search-area .search-area-textfield .MuiInputBase-formControl {
    font-size: 18px;
}
  
#notifications-page .search-area .search-area-textfield .MuiInputBase-formControl input::placeholder {
    font-weight: 600;
}

#notifications-page .search-area .search-icon {
    font-size: 34px;
}
  
#notifications-page .notification-list {
    margin: 20px 0 40px 0;
}
  
#notifications-page .notification-list .notification-group {
    margin-top: 20px;
}
  
#notifications-page .notification-list .notification-group .group-title {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
}
  
#notifications-page .notification-list .notification-group .group-title .group-title-text{
    font-size: 18px;
    font-weight: 600;
    color: #3f526d;
}
  
#notifications-page .notification-list .notification-group .group-title .clear-all-button {
    font-size: 18px;
    padding-right: 0;
    text-decoration: underline;
    text-transform: unset;
    font-weight: 600;
}
  
#notifications-page .notification-list .item-list {
    
}
  
#notifications-page .notification-list .item-list .notification-item:not(:last-child) {
    margin-bottom: 10px;
}
  
#notifications-page .notification-list .item-list .notification-item {
    border-radius: 6px;
    padding: 14px 20px;
    position: relative;
    background-color: #f9f8fd;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-avatar {
    margin-top: 11px;
}
  
  /* CONTENTS */
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section {
    padding-left: 20px;
    display: flex;
    flex-wrap: nowrap;
    flex: auto;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area {
    flex: auto;
    padding-right: 37px;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area strong {
    font-weight: 600;
    font-size: 18px;
    color: #3f526d;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #3f526d;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area p mark {
    background-color: unset;
    color: #3a60d7;
}
  
  /* ATTACHMENTS */
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .attachments {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 -10px;
    margin-bottom: -10px;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .attachments .attachment-item {
    padding: 10px;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .attachments a{
    text-decoration: none;
  
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .attachments button {
    color: #327095;
    text-transform: none;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .attachments .filename {
    font-size: 16px;
    font-weight: 600;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .attachments button .file-size {
    display: block;
    font-size: 12px;
    color: #79869f;
    text-align: left;
    font-weight: normal;
}
  
  /* Date and Remove Section */
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .date-and-remove-area b{
    color: #3f526d;
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
    position: relative;
    top: -4px;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .date-and-remove-area .remove-button {
    position: absolute;
    right: 9px;
    bottom: 6px;
}
  
#notifications-page .pagination-wrapper {
    display: flex;
    justify-content: flex-end;
}