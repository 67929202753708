.header-container {
    padding-right: 10px;
}

header#app-header {
    height: 88px;
}

header#app-header #page-header {
    height: 100%;
}

header#app-header #page-header .page-header-button-text {
    font-size: 20px;
}

header#app-header .header-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

header#app-header .action-icons {
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    flex-wrap: nowrap;
    margin-right: 64px;
    position: relative;
    height: 48px;
}

header#app-header .action-icons img {
    object-fit: contain;
}

header#app-header .action-icons.__show .action-item-wrapper:nth-child(5) { transition-delay: 100; transform: translateX(0); }
header#app-header .action-icons.__show .action-item-wrapper:nth-child(4) { transition-delay: 200; transform: translateX(-48px); }
header#app-header .action-icons.__show .action-item-wrapper:nth-child(3) { transition-delay: 300; transform: translateX(-96px); }
header#app-header .action-icons.__show .action-item-wrapper:nth-child(2) { transition-delay: 400; transform: translateX(-144px); }
header#app-header .action-icons.__show .action-item-wrapper:nth-child(1) { transition-delay: 500; transform: translateX(-192px); }

header#app-header .action-icons .action-item-wrapper:nth-child(5) { transition-delay: 500; }
header#app-header .action-icons .action-item-wrapper:nth-child(4) { transition-delay: 400; }
header#app-header .action-icons .action-item-wrapper:nth-child(3) { transition-delay: 300; }
header#app-header .action-icons .action-item-wrapper:nth-child(2) { transition-delay: 200; }
header#app-header .action-icons .action-item-wrapper:nth-child(1) { transition-delay: 100; }


header#app-header .action-icons .action-item-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0;
    transform: translateX(0);
    pointer-events: none;
    transition: 300ms ease;
    user-select: none;
}

header#app-header .action-icons.__show .action-item-wrapper {
    opacity: 1;
    pointer-events: unset;
    user-select: unset;
}


header#app-header .action-icons .action-item-wrapper.notifications-action svg {
    color: #ffc009;
    font-size: 28px;
}

header#app-header .action-icons .action-item-wrapper.notifications-action .MuiBadge-anchorOriginTopRightRectangle {
    top: 7px;
    right: 4px;
    height: 14px;
    min-width: 14px;
}

header#app-header .header-wrapper .profile-button {
    width: 28px;
    height: 28px;
    min-width: 28px;
    max-width: 28px;
    border-radius: 50%;
    margin-right: 18px;
    padding-left: 14px;
    padding-right: 14px;
}

header#app-header .header-wrapper .profile-picture {
    width: 64px;
    height: 64px;
}